import React from 'react';

import classnames from 'classnames';

import classes from './styles.module.scss';

export default function StyledButton({
  label,
  disabled,
  onClick,
  isDashboard,
  style,
  isSearch,
  isEdit,
  isAuth,
}) {
  return (
    <button
      className={classnames(
        classes.StyledButton,
        isAuth && classes.isAuth,
        isDashboard && classes.isDashboard,
        isSearch && classes.isSearch,
        isEdit && classes.isEdit
      )}
      style={style}
      disabled={disabled}
      type="button"
      onClick={onClick}
    >
      {label}
    </button>
  );
}
