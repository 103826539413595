import React, { useState, useEffect } from 'react';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import Video from './Video';
import Button from '../../Form/StyledButton';
import Checkbox from '../../Form/Checkbox';

import classes from './styles.module.scss';

export default function AutoCropForm({
  assets,
  invokeTaskHandler,
  projectId,
  reFetchRenders,
  onClose,
  createProject,
  // updateProject,
}) {
  const [globalOptions, setGlobalOptions] = useState([
    { id: 'global169', name: '169', isChecked: false, label: '16:9' },
    { id: 'global916', name: '916', isChecked: false, label: '9:16' },
    { id: 'global11', name: '11', isChecked: false, label: '1:1' },
  ]);
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    if (assets) {
      setVideos(
        assets.map((asset) => {
          return { asset, options: [] };
        })
      );
    }
  }, [assets]);

  const changeGlobalOptions = (event) => {
    setGlobalOptions((prevOptions) => {
      const index = prevOptions.findIndex(
        (elem) => elem.name === event.target.name
      );
      const newOptions = [...prevOptions];
      if (newOptions[index].isChecked) {
        newOptions[index].isChecked = false;
      } else {
        newOptions[index].isChecked = true;
      }
      return newOptions;
    });
  };

  const changeSingleVideoOptions = (event) => {
    setVideos((prevVideos) => {
      const newVideos = prevVideos.map((video) => {
        if (video.asset.assetId === event.target.dataset.id) {
          if (video.options.includes(event.target.name)) {
            return {
              ...video,
              options: video.options.filter(
                (option) => option !== event.target.name
              ),
            };
          }
          return { ...video, options: [...video.options, event.target.name] };
        }
        return video;
      });
      return newVideos;
    });
  };

  const toggleSelectAll = () => {
    setGlobalOptions((prevOptions) => {
      if (prevOptions.every((option) => option.isChecked)) {
        const newOptions = [...prevOptions];
        newOptions.forEach((value, index) => {
          newOptions[index].isChecked = false;
        });
        return newOptions;
      }
      const newOptions = [...prevOptions];
      newOptions.forEach((value, index) => {
        newOptions[index].isChecked = true;
      });
      return newOptions;
    });
  };

  const createTask = async () => {
    let id = projectId;
    if (!projectId && createProject) {
      id = uuidv4();
      await createProject({
        projectId: id,
        title: `My Crop Project ${moment().format('h:mm:ss a')}`,
        description: `My crop project created at ${moment().format(
          'MMMM Do YYYY, h:mm:ss a'
        )}`,
        assets: videos.map((video) => video.asset.assetId),
      });
    }
    const pipelines = [];
    videos.forEach((video) => {
      video.options.forEach((option) => {
        let aspectRatio;
        if (option === '169') {
          aspectRatio = 16 / 9;
        } else if (option === '916') {
          aspectRatio = 9 / 16;
        } else if (option === '11') {
          aspectRatio = 1 / 1;
        }
        pipelines.push({
          assets: [video.asset.assetId],
          taskOrder: ['autoCrop'],
          autoCrop: {
            aspectRatio,
          },
        });
      });
    });

    await invokeTaskHandler({
      projectId: id,
      callId: uuidv4(),
      pipelines,
    });

    /* await updateProject({
      projectId,
      tasks: response.data.invokeTaskHandler.parentTaskIds,
    }); */

    reFetchRenders();
    onClose();
  };

  useEffect(() => {
    const options = [];
    globalOptions.forEach((option) => {
      if (option.isChecked) {
        options.push(option.name);
      }
    });

    setVideos((prevVideos) => {
      const newVideos = prevVideos.map((video) => {
        return { ...video, options };
      });
      return newVideos;
    });
  }, [globalOptions]);

  useEffect(() => {
    if (
      videos.length &&
      videos.every((video) => video.options.includes('169')) &&
      !globalOptions[0].isChecked
    ) {
      setGlobalOptions((prevOptions) => {
        const newOptions = prevOptions.map((option) => {
          if (option.name === '169') {
            return { ...option, isChecked: true };
          }
          return option;
        });
        return newOptions;
      });
    } else if (
      videos.length &&
      videos.every((video) => !video.options.includes('169')) &&
      globalOptions[0].isChecked
    ) {
      setGlobalOptions((prevOptions) => {
        const newOptions = prevOptions.map((option) => {
          if (option.name === '169') {
            return { ...option, isChecked: false };
          }
          return option;
        });
        return newOptions;
      });
    }

    if (
      videos.length &&
      videos.every((video) => video.options.includes('11')) &&
      !globalOptions[2].isChecked
    ) {
      setGlobalOptions((prevOptions) => {
        const newOptions = prevOptions.map((option) => {
          if (option.name === '11') {
            return { ...option, isChecked: true };
          }
          return option;
        });
        return newOptions;
      });
    } else if (
      videos.length &&
      videos.every((video) => !video.options.includes('11')) &&
      globalOptions[2].isChecked
    ) {
      setGlobalOptions((prevOptions) => {
        const newOptions = prevOptions.map((option) => {
          if (option.name === '11') {
            return { ...option, isChecked: false };
          }
          return option;
        });
        return newOptions;
      });
    }

    if (
      videos.length &&
      videos.every((video) => video.options.includes('916')) &&
      !globalOptions[1].isChecked
    ) {
      setGlobalOptions((prevOptions) => {
        const newOptions = prevOptions.map((option) => {
          if (option.name === '916') {
            return { ...option, isChecked: true };
          }
          return option;
        });
        return newOptions;
      });
    } else if (
      videos.length &&
      videos.every((video) => !video.options.includes('916')) &&
      globalOptions[1].isChecked
    ) {
      setGlobalOptions((prevOptions) => {
        const newOptions = prevOptions.map((option) => {
          if (option.name === '916') {
            return { ...option, isChecked: false };
          }
          return option;
        });
        return newOptions;
      });
    }
  }, [videos]);

  return (
    <div className={classes.AutoCropForm}>
      <div className={classes.header}>
        {globalOptions.map((value) => {
          return (
            <div className={classes.checkboxContainer} key={value.id}>
              <Checkbox
                id={value.id}
                isChecked={value.isChecked}
                name={value.name}
                label={value.label}
                onChange={changeGlobalOptions}
              />
            </div>
          );
        })}
        <span className={classes.selectAll} onClick={toggleSelectAll}>
          Select All
        </span>
      </div>
      <div className={classes.content}>
        {videos.map((video) => {
          return (
            <Video
              key={video.asset.assetId}
              onOptionsChange={changeSingleVideoOptions}
              video={video}
              globalOptions={globalOptions}
            />
          );
        })}
      </div>

      <Button
        onClick={createTask}
        label="Crop"
        style={{
          width: 160,
          height: 40,
          borderRadius: 5,
          marginBottom: 42,
          marginTop: 39,
        }}
      />
    </div>
  );
}
