import React from 'react';
import { useHistory } from 'react-router-dom';

import moment from 'moment';

import classes from './styles.module.scss';

const ProjectsItem = ({ project }) => {
  const history = useHistory();
  return (
    <tr
      className={classes.tableRow}
      key={project.id}
      onClick={() => history.push(`/projects/${project.projectId}`)}
    >
      <td className={classes.td}>{project.title}</td>
      <td className={classes.td}>{project.description}</td>
      <td className={classes.td}>{moment(project.createdAt).format('lll')}</td>
    </tr>
  );
};

export default ProjectsItem;
