/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';

import { ProgressBar } from 'react-bootstrap';
import classnames from 'classnames';
import { graphql, compose } from 'react-apollo';
import { graphqlMutation } from 'aws-appsync-react';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';

import RadioButton from '../../Form/RadioButton';
import DragAndDropUploader from '../../Content/DragAndDropUploader';
import {
  listAllAssets,
  listUsersProjects,
  getTasksInProgress,
} from '../../../graphQL/queries';
import {
  CreateProject,
  CrateAutoEditTask,
  CreateAsset,
} from '../../../graphQL/mutations';

import StyledButton from '../../Form/StyledButton';

import classes from './styles.module.scss';

const AutoEditForm = ({
  onClose,
  invokeAPUpload,
  createProject,

  invokeTaskHandler,
}) => {
  const [project, setProject] = useState({
    name: '',
    description: '',
  });
  const [settings, setSettings] = useState({
    duration: '',
    pacing: '',
    aspectRatio: '',
  });

  const history = useHistory();

  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [projectId, setProjectId] = useState(null);
  const [areSettingsSaved, setAreSettingsSaved] = useState(false);

  const onChangeHandler = (e) => {
    setProject((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const radioChangeHandler = (e) => {
    setSettings((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const createTask = () => {
    console.log(settings.duration);
    const aspectRatioParts = settings.aspectRatio.split(':');
    const aspectRatioFloat = +aspectRatioParts[0] / +aspectRatioParts[1];
    invokeTaskHandler({
      callId: uuidv4(),
      assets: files.map((file) => file.uuid),
      projectId,
      pace: settings.pacing,
      targetDuration: parseInt(settings.duration, 10),
      aspectRatio: aspectRatioFloat,
    }).then(() => history.push(`/projects/${projectId}`));
  };

  const createProjectFromUploadedAssets = () => {
    if (!areSettingsSaved) {
      return;
    }
    const id = uuidv4();
    createProject({
      projectId: id,
      title: project.name,
      description: project.description,
      assets: files.map((file) => file.uuid),
      createdAt: new Date().toISOString(),
    }).then(() => setProjectId(id));
  };

  useEffect(() => {
    if (projectId) {
      createTask();
    }
  }, [projectId]);

  useEffect(() => {
    if (areSettingsSaved && uploadProgress === 100 && !projectId) {
      createProjectFromUploadedAssets();
    }
  }, [areSettingsSaved, projectId, uploadProgress]);

  useEffect(() => {
    if (files.length) {
      let progress = 0;
      files.forEach((file) => {
        progress += file.uploadProgress;
      });
      setUploadProgress(progress / files.length);
    }
  }, [files]);

  return (
    <>
      <div className={classes.CreateProject}>
        {!isUploading ? (
          <>
            <DragAndDropUploader
              files={files}
              setFiles={setFiles}
              setIsUploading={setIsUploading}
              invokeAPUpload={invokeAPUpload}
              onClose={() => {}}
              onAdd={createProjectFromUploadedAssets}
              multiple
            />
          </>
        ) : (
          <div className={classes.autoEditContainer}>
            <ProgressBar now={uploadProgress} bsPrefix={classes.progressBar} />
            <div className={classes.uploading}>
              {uploadProgress === 100 ? 'Uploaded' : 'Uploading...'}
            </div>
            <div className={classes.formContainer}>
              <form className={classes.form}>
                <div className={classes.fieldContainer}>
                  <input
                    name="name"
                    value={project.name}
                    type="text"
                    placeholder="Project Name"
                    className={classes.formInput}
                    onChange={onChangeHandler}
                  />
                </div>
                <div className={classes.fieldContainer}>
                  <textarea
                    name="description"
                    value={project.description}
                    placeholder="Project Description"
                    className={classnames(
                      classes.formInput,
                      classes.formDescription
                    )}
                    onChange={onChangeHandler}
                  />
                </div>
              </form>
            </div>
            <div className={classes.settingsContainer}>
              <div className={classes.item}>
                <div className={classes.text}>Duration</div>
                <div className={classes.radioItem}>
                  <RadioButton
                    changed={radioChangeHandler}
                    id="duration1"
                    isSelected={settings.duration === '6s'}
                    label="6s"
                    value="6s"
                    name="duration"
                  />
                </div>
                <div className={classes.radioItem}>
                  <RadioButton
                    changed={radioChangeHandler}
                    id="duration2"
                    isSelected={settings.duration === '15s'}
                    label="15s"
                    value="15s"
                    name="duration"
                  />
                </div>
                <div className={classes.radioItem}>
                  <RadioButton
                    changed={radioChangeHandler}
                    id="duration3"
                    isSelected={settings.duration === '30s'}
                    label="30s"
                    value="30s"
                    name="duration"
                  />
                </div>
              </div>
              <div className={classes.item}>
                <div className={classes.text}>Pacing</div>
                <div className={classes.radioItem}>
                  <RadioButton
                    changed={radioChangeHandler}
                    id="pacing1"
                    isSelected={settings.pacing === 'Fast'}
                    label="Fast"
                    value="Fast"
                    name="pacing"
                  />
                </div>
                <div className={classes.radioItem}>
                  <RadioButton
                    changed={radioChangeHandler}
                    id="pacing2"
                    isSelected={settings.pacing === 'Slow'}
                    label="Slow"
                    value="Slow"
                    name="pacing"
                  />
                </div>
              </div>
              <div className={classes.item}>
                <div className={classes.text}>Aspect Ratio</div>
                <div className={classes.radioItem}>
                  <RadioButton
                    changed={radioChangeHandler}
                    id="aspectRatio1"
                    isSelected={settings.aspectRatio === '16:9'}
                    label="16:9"
                    value="16:9"
                    name="aspectRatio"
                  />
                </div>
                <div className={classes.radioItem}>
                  <RadioButton
                    changed={radioChangeHandler}
                    id="aspectRatio2"
                    isSelected={settings.aspectRatio === '9:16'}
                    label="9:16"
                    value="9:16"
                    name="aspectRatio"
                  />
                </div>
                <div className={classes.radioItem}>
                  <RadioButton
                    changed={radioChangeHandler}
                    id="aspectRatio3"
                    isSelected={settings.aspectRatio === '1:1'}
                    label="1:1"
                    value="1:1"
                    name="aspectRatio"
                  />
                </div>
              </div>
            </div>
            <div className={classes.btnContainer}>
              <StyledButton
                label="Save"
                isForm
                onClick={() => {
                  setAreSettingsSaved(true);
                }}
                disabled={
                  !project.name ||
                  !project.description ||
                  !settings.aspectRatio ||
                  !settings.pacing ||
                  !settings.duration
                }
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default compose(
  graphql(listAllAssets, {
    options: {
      fetchPolicy: 'network-only',
    },
    props: (props) => ({
      assets: props.data.listAssets ? props.data.listAssets.items : [],
    }),
  }),

  graphqlMutation(CreateAsset, listAllAssets, 'Asset'),
  graphqlMutation(CreateProject, listUsersProjects, 'Project'),
  graphqlMutation(CrateAutoEditTask, getTasksInProgress, 'Task')
)(AutoEditForm);
