import React, { useEffect } from 'react';

import classnames from 'classnames';

import classes from './styles.module.scss';

const Alert = ({ message, clearMessage }) => {
  useEffect(() => {
    setTimeout(() => {
      if (message) clearMessage();
    }, 4000);
  }, [message]);

  return (
    <div className={classnames(classes.Alert, message && classes.show)}>
      <p className={classes.message}>{message}</p>
    </div>
  );
};

export default Alert;
