import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Card, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Header from '../../../components/Header';
import FormField from '../../../components/Form/FormField';
import FormButton from '../../../components/Form/StyledButton';
import Alert from '../../../components/Alert';

import classes from './styles.module.scss';

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email'),
  code: Yup.string().required('Please enter your email'),
  new_password: Yup.string()
    .min(8, 'Too Short!')
    .required('Please enter password'),
  confirm_new_password: Yup.string()
    .min(8, 'Too Short!')
    .required('Please confirm your password')
    .oneOf([Yup.ref('new_password'), null], 'Password mismatch'),
});

const ForgotPage = () => {
  const history = useHistory();
  const [error, setError] = useState(null);

  return (
    <>
      <Header />
      <div className={classes.FormContainer}>
        <Card className={classes.Card}>
          <Card.Body className={classes.cardBody}>
            <Formik
              initialValues={{
                email: '',
                code: '',
                new_password: '',
                confirm_new_password: '',
              }}
              validationSchema={ValidationSchema}
              onSubmit={(values) => {
                // eslint-disable-next-line camelcase
                const { email, code, new_password } = values;
                const username = email;

                Auth.forgotPasswordSubmit(username, code, new_password)
                  .then(() => history.push('/login'))
                  .catch((err) => {
                    setError(err.message);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                // isSubmitting,
              }) => (
                <Form className={classes.form} noValidate>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      type="text"
                      placeholder="Email"
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                      value={values.email}
                      isInvalid={touched.email && errors.email}
                    />
                  </div>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      type="text"
                      placeholder="Verify code"
                      onChange={handleChange('code')}
                      onBlur={handleBlur('code')}
                      value={values.code}
                      isInvalid={touched.code && errors.code}
                    />
                  </div>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      type="password"
                      placeholder="New Password"
                      onChange={handleChange('new_password')}
                      onBlur={handleBlur('new_password')}
                      value={values.new_password}
                      isInvalid={touched.new_password && errors.new_password}
                    />
                  </div>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      type="password"
                      placeholder="Confirm New Password"
                      onChange={handleChange('confirm_new_password')}
                      onBlur={handleBlur('confirm_new_password')}
                      value={values.confirm_new_password}
                      isInvalid={
                        touched.confirm_new_password &&
                        errors.confirm_new_password
                      }
                    />
                  </div>
                  <div className={classes.formBtnContainer}>
                    <FormButton label="Submit" onClick={handleSubmit} isAuth />
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </div>
      <Alert message={error} clearMessage={() => setError(null)} />
    </>
  );
};

export default ForgotPage;
