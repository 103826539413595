import React from 'react';

import Form from 'react-bootstrap/Form';
import MaskInput from 'react-maskinput';
import classnames from 'classnames';

import classes from './styles.module.scss';

export default function ExpCardDateField({
  type,
  placeholder,
  value,
  onChange,
  readOnly,
  onBlur,
  isInvalid,
}) {
  return (
    <Form.Group className={classes.ExpCardDateField}>
      <MaskInput
        maskChar=" "
        mask="00 0000"
        className={classnames(
          classes.formControl,
          isInvalid && classes.isInvalid
        )}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        onBlur={onBlur}
      />
    </Form.Group>
  );
}
