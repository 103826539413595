import React, { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import LoginPage from '../pages/Auth/LoginPage';
import RegistrationPage from '../pages/Auth/RegistrationPage';
import VerifyPage from '../pages/Auth/VerifyPage';
import ForgotPage from '../pages/Auth/ForgotPage';
import ConfirmCodePage from '../pages/Auth/ConfirmCodePage';

import DashboardPage from '../pages/Dashboard';
import ContentPage from '../pages/Content';
import ProjectsPage from '../pages/Projects';
import ProjectPage from '../pages/Project';
import SearchPage from '../pages/Search';
import SettingsPage from '../pages/Settings';

import { UserContext } from '../context';

import Layout from '../components/Layout';
import { hasAuthData } from '../helpers/authStorage';

const Routes = () => {
  const [isAuth, setIsAuth] = useState(false);
  const [userData, setUserData] = useState(false);

  const checkAuth = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      if (user.email_verified) {
        setIsAuth(!!user);
        setUserData({ user });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    checkAuth();
  }, []);

  console.log(isAuth);
  return (
    <BrowserRouter>
      <UserContext.Provider value={[isAuth, setIsAuth, userData, setUserData]}>
        <Switch>
          <Route
            path="/dashboard"
            exact
            render={() =>
              hasAuthData() ? (
                <Layout>
                  <DashboardPage />
                </Layout>
              ) : (
                <Redirect
                  to={{
                    pathname: '/login',
                  }}
                />
              )
            }
          />
          <Route
            path="/content"
            exact
            render={() =>
              hasAuthData() ? (
                <Layout>
                  <ContentPage />
                </Layout>
              ) : (
                <Redirect
                  to={{
                    pathname: '/login',
                  }}
                />
              )
            }
          />
          <Route
            path="/projects"
            exact
            render={() =>
              hasAuthData() ? (
                <Layout>
                  <ProjectsPage />
                </Layout>
              ) : (
                <Redirect
                  to={{
                    pathname: '/login',
                  }}
                />
              )
            }
          />
          <Route
            path="/projects/:id"
            exact
            render={() =>
              hasAuthData() ? (
                <Layout>
                  <ProjectPage />
                </Layout>
              ) : (
                <Redirect
                  to={{
                    pathname: '/login',
                  }}
                />
              )
            }
          />
          <Route
            path="/search/:id"
            exact
            render={() =>
              hasAuthData() ? (
                <Layout>
                  <SearchPage />
                </Layout>
              ) : (
                <Redirect
                  to={{
                    pathname: '/login',
                  }}
                />
              )
            }
          />
          <Route
            path="/settings"
            exact
            render={() =>
              hasAuthData() ? (
                <Layout>
                  <SettingsPage />
                </Layout>
              ) : (
                <Redirect
                  to={{
                    pathname: '/login',
                  }}
                />
              )
            }
          />
          <Route
            path="/"
            exact
            render={() =>
              hasAuthData() ? (
                <Redirect
                  to={{
                    pathname: '/dashboard',
                  }}
                />
              ) : (
                <Redirect
                  to={{
                    pathname: '/login',
                  }}
                />
              )
            }
          />

          <Route path="/login" exact component={LoginPage} />
          <Route path="/registration" exact component={RegistrationPage} />
          <Route path="/verify" exact component={VerifyPage} />
          <Route path="/forgot" exact component={ForgotPage} />
          <Route path="/confirmCode" exact component={ConfirmCodePage} />

          <Redirect to="/login" />
        </Switch>
      </UserContext.Provider>
    </BrowserRouter>
  );
};

export default Routes;
