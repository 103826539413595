import gql from 'graphql-tag';

export const listAllAssets = gql`
  query listAllAssets {
    listAssets {
      items {
        uploadStatus
        foveaType
        title
        storyline
        srcMediainfo
        fileType
        projects
        parent_asset
        mp4Urls
        mp4Outputs
        hlsUrl
        hlsPlaylist
        description
        assetId
      }
    }
  }
`;

export const listParentAssets = gql`
  query listParentAssets {
    listAssets(
      filter: {
        or: [
          { foveaType: { eq: "raw_video" } }
          { foveaType: { eq: "auto-edit" } }
        ]
      }
      nextToken: ""
    ) {
      items {
        foveaType
        assetId
        hlsUrl
        title
        description
        aspectRatio
        srcMediainfo
        mp4Urls
      }
      nextToken
    }
  }
`;

export const getTasksInProgress = gql`
  query getTasksInProgress {
    listTasks {
      items {
        parent_progress
        taskId
        task
        project_id
      }
    }
  }
`;

export const listUsersProjects = gql`
  query listUsersProjects {
    listProjects {
      items {
        createdAt
        title
        projectId
        tasks
        processingStatus
        lastModified
        description
        processingInstructions
        assets
      }
    }
  }
`;

export const getProject = gql`
  query GetProject($projectId: String!) {
    getProject(projectId: $projectId) {
      projectId
      title
      description
      assets
      tasks
    }
  }
`;

export const getChildAssets = gql`
  query listChildAssets($parentAssetId: String!, $nextToken: String = "") {
    listChildAssets(
      filter: { parentAsset: { eq: $parentAssetId } }
      nextToken: $nextToken
    ) {
      nextToken
      items {
        foveaType
        assetId
        parentAsset
        hlsUrl
        title
        description
        aspectRatio
        autoEditDuration
        pace
        srcMediainfo
      }
    }
  }
`;

export const getFilteredAssets = gql`
  query listCropAssets(
    $aspectRatio: Float!
    $pace: String!
    $nextToken: String = ""
  ) {
    listAssets(
      filter: {
        or: [
          { aspectRatio: { eq: $aspectRatio } }
          { pace: { eq: $pace } }
          { aspectRatio2: { eq: $aspectRatio2 } }
        ]
      }
      nextToken: $nextToken
    ) {
      items {
        foveaType
        assetId
        parentAsset
        hlsUrl
        title
        description
        aspectRatio
        autoEditDuration
        pace
        srcMediainfo
        mp4Urls
      }
      nextToken
    }
  }
`;

export const getCropAssets = gql`
  query listCropAssets($aspectRatio: Float!, $nextToken: String = "") {
    listAssets(
      filter: { aspectRatio: { eq: $aspectRatio } }
      nextToken: $nextToken
    ) {
      items {
        foveaType
        assetId
        parentAsset
        hlsUrl
        title
        description
        aspectRatio
        autoEditDuration
        pace
        srcMediainfo
        mp4Urls
      }
      nextToken
    }
  }
`;

export const getDurationAssets = gql`
  query listDurationAssets($autoEditDuration: Float!, $nextToken: String = "") {
    listAssets(
      filter: { autoEditDuration: { eq: $autoEditDuration } }
      nextToken: $nextToken
    ) {
      items {
        foveaType
        assetId
        parentAsset
        hlsUrl
        title
        description
        aspectRatio
        pace
        srcMediainfo
        mp4Urls
      }
      nextToken
    }
  }
`;

export const getPaceAssets = gql`
  query listPaceAssets($pace: String!, $nextToken: String = "") {
    listAssets(filter: { pace: { eq: $pace } }, nextToken: $nextToken) {
      items {
        foveaType
        assetId
        parentAsset
        hlsUrl
        title
        description
        aspectRatio
        autoEditDuration
        pace
        srcMediainfo
        mp4Urls
      }
      nextToken
    }
  }
`;

/* export const getAsset = gql`
  query getAsset($assetId: String!) {
    getAsset(
      filter: { assetId: { eq: "0521ec5e-95b9-4b38-bfcc-f4d9fe5592fa" } }
    ) {
      foveaType
      assetId
      parentAsset
      hlsUrl
      title
      description
      aspectRatio
      autoEditDuration
      pace
      srcMediainfo
      mp4Urls
    }
  }
`;
 */
/* export const getFullProject = gql`
  query GetFullProject($projectId: String!) {
    getFullProject(projectId: $projectId) {
      title
      description
      assets
      tasks
    }
  }
`;

export const getAsset = gql`
  query filterRenders {
    listAssets(filter: { type: { eq: "auto-crop" } }) {
      items {
        assetId
        hlsUrl
      }
    }
  }
`;
 */
