/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import Switch from 'react-switch';
import { graphql, compose } from 'react-apollo';
import { graphqlMutation } from 'aws-appsync-react';
import { v4 as uuidv4 } from 'uuid';

import Button from '../../components/Form/StyledButton';
import AssetPreview from './AssetPreview';

import classes from './styles.module.scss';
import { listAllAssets, getProject } from '../../graphQL/queries';
import { CreateMultipleTasks } from '../../graphQL/mutations';

const SearchPage = ({ assets, client, invokeTaskHandler }) => {
  const [transcript, setTranscript] = useState(false);
  const [celebrities, setCelebrities] = useState(false);
  const [textLabels, setTextLabels] = useState(false);
  const [objects, setObjects] = useState(false);
  const [project, setProject] = useState({
    title: '',
    description: '',
    assets: [],
  });
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  const { id } = useParams();
  const history = useHistory();

  const getItem = async () => {
    const result = await client.query({
      query: getProject,
      variables: { projectId: id },
    });

    setProject(result.data.getProject);
  };

  const search = async () => {
    if (
      searchTerm.trim() === '' &&
      !transcript &&
      !celebrities &&
      !textLabels &&
      !objects
    ) {
      console.log('not valid');
      return;
    }
    console.log('search');
    const searchOptions = [];
    if (transcript) {
      searchOptions.push('transcript');
    }
    if (celebrities) {
      // celebrities search is not yet implemented on backend
      // searchOptions.push('celebrities');
      searchOptions.push('transcript');
    }
    if (textLabels) {
      // textLabels search is not yet implemented on backend
      // searchOptions.push('textLabels');
      searchOptions.push('object');
    }
    if (objects) {
      searchOptions.push('object');
    }
    const pipelines = searchOptions.map((option) => {
      return {
        assets: filteredAssets.map((asset) => asset.assetId),
        taskOrder: ['autoSearch'],
        autoSearch: { query: searchTerm, searchType: option },
      };
    });

    await invokeTaskHandler({
      callId: uuidv4(),
      projectId: id,
      pipelines,
    });
  };

  useEffect(() => {
    getItem();
  }, []);

  useEffect(() => {
    if (project.assets.length) {
      const assetsByProject = assets.filter((asset) =>
        project.assets.includes(asset.assetId)
      );
      setFilteredAssets(assetsByProject);
    }
  }, [project]);

  return (
    <>
      <div className={classes.ProjectPage}>
        <div className={classes.projectSection}>
          <div className={classes.projectInfo}>
            <div className={classes.boxProject}>
              <div className={classes.projectName}>Project Name</div>
              <div className={classes.projectDescription}>Project Name</div>
            </div>
          </div>
          <div className={classes.btnContainerAction}>
            <div className={classes.btnContainer}>
              <Button
                label="Project Page"
                onClick={() => history.push(`/projects/${id}`)}
              />
            </div>
          </div>
        </div>
        <div className={classes.searchSection}>
          <div className={classes.searchResultBox}>
            {filteredAssets.map((asset) => {
              return <AssetPreview key={asset.assetId} asset={asset} />;
            })}
          </div>
          <div className={classes.searchFilterBox}>
            <div className={classes.searchField}>
              <i className={classes.searchIcon}>search</i>
              <input
                type="text"
                className={classes.inputField}
                value={searchTerm}
                onChange={(event) => setSearchTerm(event.target.value)}
              />
            </div>
            <div className={classes.switcherContainer}>
              <div className={classes.switchItem}>
                <div className={classes.text}>Transcript</div>
                <div className={classes.switch}>
                  <Switch
                    onChange={(checked) => setTranscript(checked)}
                    checked={transcript}
                    className="react-switch"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor="#7E57C2"
                    onHandleColor="#fff"
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={40}
                  />
                </div>
              </div>
              <div className={classes.switchItem}>
                <div className={classes.text}>Celebrities</div>
                <div className={classes.switch}>
                  <Switch
                    onChange={(checked) => setCelebrities(checked)}
                    checked={celebrities}
                    className="react-switch"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor="#7E57C2"
                    onHandleColor="#fff"
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={40}
                  />
                </div>
              </div>
              <div className={classes.switchItem}>
                <div className={classes.text}>Text labels</div>
                <div className={classes.switch}>
                  <Switch
                    onChange={(checked) => setTextLabels(checked)}
                    checked={textLabels}
                    className="react-switch"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor="#7E57C2"
                    onHandleColor="#fff"
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={40}
                  />
                </div>
              </div>
              <div className={classes.switchItem}>
                <div className={classes.text}>Objects</div>
                <div className={classes.switch}>
                  <Switch
                    onChange={(checked) => setObjects(checked)}
                    checked={objects}
                    className="react-switch"
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor="#7E57C2"
                    onHandleColor="#fff"
                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                    height={20}
                    width={40}
                  />
                </div>
              </div>
            </div>
            <div className={classes.btnContainer}>
              <Button label="Search" onClick={search} />
            </div>
          </div>
        </div>
        <div className={classes.guiSection}>
          <div>GUI</div>
        </div>
      </div>
    </>
  );
};

export default compose(
  graphql(listAllAssets, {
    options: {
      fetchPolicy: 'network-only',
    },
    props: (props) => ({
      assets: props.data.listAssets ? props.data.listAssets.items : [],
    }),
  }),
  graphqlMutation(CreateMultipleTasks, null, 'Project')
)(SearchPage);
