/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useState, useRef, useEffect } from 'react';

// import axios from 'axios';
// import fileDownload from 'js-file-download';
import classnames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import Button from '../../Form/StyledButton';
import AutoCropForm from '../AutoCropForm';
import classes from './styles.module.scss';
import downloadFile from '../../../helpers/downloadFile';

import { getChildAssets } from '../../../graphQL/queries';
import { CreateAsset } from '../../../graphQL/mutations';

const buttonStyle = {
  width: 160,
  height: 40,
  borderRadius: 5,
  marginLeft: 0,
  marginRight: 0,
};

const outlineButtonStyle = {
  width: 160,
  height: 40,
  borderRadius: 5,
  marginLeft: 0,
  marginRight: 0,
  backgroundColor: 'white',
  border: '2px solid #7E57C2',
  color: '#7E57C2',
};

export default function RenderDetails({
  currentAsset,
  onDeleteAsset,
  // assets,
  onClose,
  projectId,
  reFetchRenders,
  invokeTaskHandler,
  client,
}) {
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const [cropOption, setCropOption] = useState('169');
  const [videoSrc, setVideoSrc] = useState(null);
  const [isAutoCropMode, setIsAutoCropMode] = useState(false);
  const [childAssets, setChildAssets] = useState([]);

  // const srcMediainfo = JSON.parse(currentAsset.srcMediainfo);
  // const videoUrl = currentAsset.mp4Urls ? currentAsset.mp4Urls[0] : null;

  const videoRef = useRef();

  useEffect(async () => {
    if (currentAsset) {
      const result = await client.query({
        query: getChildAssets,
        variables: { parentAssetId: currentAsset.assetId },
      });
      console.log('child assets', result.data.listChildAssets.items);
      setChildAssets(result.data.listChildAssets.items);
    }
  }, [currentAsset]);

  /* const downloadFile = () => {
    axios
      .get(videoSrc, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, currentAsset.title);
      });
  };
 */

  /* useEffect(() => {
    let aspectRatio;
    if (+srcMediainfo?.video[0].aspectRatio === 1) {
      aspectRatio = '11';
    } else if (+srcMediainfo?.video[0].aspectRatio === 0.5625) {
      aspectRatio = '916';
    } else {
      aspectRatio = '169';
    }
    setCropOption(aspectRatio);
    setVideoSrc(currentAsset.mp4Urls ? currentAsset.mp4Urls[0] : null);
  }, []);
 */

  useEffect(() => {
    if (childAssets) {
      let currentChildAsset;
      if (cropOption === '169') {
        currentChildAsset = childAssets.find(
          (asset) => asset.aspectRatio === 1.7778
        );
      } else if (cropOption === '916') {
        currentChildAsset = childAssets.find(
          (asset) => asset.aspectRatio === 0.5625
        );
      } else if (cropOption === '11') {
        currentChildAsset = childAssets.find(
          (asset) => asset.aspectRatio === 1
        );
      }
      console.log(childAssets);
      if (currentChildAsset) {
        setVideoSrc(
          currentChildAsset.mp4Urls ? currentChildAsset.mp4Urls[0] : null
        );
      } else {
        setVideoSrc(currentAsset.mp4Urls ? currentAsset.mp4Urls[0] : null);
      }
    }
  }, [childAssets, cropOption]);

  useEffect(() => {
    console.log('src', videoSrc);
    videoRef.current.load();
  }, [videoSrc]);

  /*  useEffect(() => {
    console.log('changing crop option', cropOption);
    const filteredAssets = assets.filter(
      (asset) => asset.parent_asset === currentAsset.parent_asset
    );
    const filteredAssetsWithSrcMediainfo = filteredAssets.map((asset) => {
      return { ...asset, srcMediainfo: JSON.parse(asset.srcMediainfo) };
    });
    const assetWithSelectedAspectRatio = filteredAssetsWithSrcMediainfo.find(
      (asset) => {
        if (cropOption === '169') {
          return (
            +asset.srcMediainfo?.video[0].aspectRatio === 16 / 9 ||
            +asset.srcMediainfo?.video[0].aspectRatio === 1.778
          );
        }
        if (cropOption === '916') {
          return +asset.srcMediainfo?.video[0].aspectRatio === 9 / 16;
        }
        if (cropOption === '11') {
          return +asset.srcMediainfo?.video[0].aspectRatio === 1;
        }
        return currentAsset;
      }
    );
    console.log(assetWithSelectedAspectRatio);
    setVideoSrc(
      assetWithSelectedAspectRatio?.mp4Urls
        ? assetWithSelectedAspectRatio.mp4Urls[0]
        : null
    );
  }, [cropOption]); */

  useEffect(() => {
    const hideOverlay = () => {
      setIsOverlayVisible(false);
    };
    const showOverlay = () => {
      if (videoRef?.current?.seeking) {
        return;
      }
      setIsOverlayVisible(true);
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('playing', hideOverlay);
      videoRef.current.addEventListener('pause', showOverlay);
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('playing', hideOverlay);
        videoRef.current.removeEventListener('pause', showOverlay);
      }
    };
  }, [videoRef]);

  const duplicateAsset = async () => {
    console.log(CreateAsset, currentAsset);
    await client.mutate({
      mutation: CreateAsset,
      variables: {
        ...currentAsset,
        title: `${currentAsset.title}(2)`,
        assetId: uuidv4(),
      },
    });
    reFetchRenders();
    onClose();
  };

  return (
    <>
      {isAutoCropMode ? (
        <AutoCropForm
          assets={[currentAsset]}
          projectId={projectId}
          reFetchRenders={reFetchRenders}
          invokeTaskHandler={invokeTaskHandler}
          onClose={onClose}
        />
      ) : (
        <div className={classes.RenderDetails}>
          <div className={classes.content}>
            <div className={classes.leftColumn}>
              <input
                type="text"
                placeholder="Project Name"
                value={projectName}
                onChange={(event) => setProjectName(event.target.value)}
              />
              <div className={classes.videoContainer}>
                <div
                  className={classes.overlay}
                  style={{ display: isOverlayVisible ? 'flex' : 'none' }}
                  onClick={() => videoRef.current.play()}
                >
                  <div className={classes.playButton} role="button" />
                </div>
                <video
                  controls={!isOverlayVisible}
                  src={videoSrc}
                  ref={videoRef}
                />
              </div>
            </div>
            <div className={classes.rightColumn}>
              <textarea
                placeholder="Project Description"
                value={projectDescription}
                onChange={(event) => setProjectDescription(event.target.value)}
              />
              <div className={classes.filters}>
                <i
                  onClick={() => setCropOption('169')}
                  role="button"
                  tabIndex={0}
                  className={classnames(
                    classes.sixteenNine,
                    cropOption === '169' && classes.active
                  )}
                >
                  16:9
                </i>
                <i
                  onClick={() => setCropOption('916')}
                  role="button"
                  tabIndex={0}
                  className={classnames(
                    classes.nineSixteen,
                    cropOption === '916' && classes.active
                  )}
                >
                  9:16
                </i>
                <i
                  onClick={() => setCropOption('11')}
                  role="button"
                  tabIndex={0}
                  className={classnames(
                    classes.oneOne,
                    cropOption === '11' && classes.active
                  )}
                >
                  1:1
                </i>
              </div>
              <div className={classes.buttonsContainer}>
                <Button
                  style={buttonStyle}
                  label="Download"
                  onClick={() => downloadFile(videoSrc, currentAsset)}
                />
                <Button style={buttonStyle} label="Share" />
                <Button
                  style={outlineButtonStyle}
                  label="Delete"
                  onClick={() => {
                    onDeleteAsset();
                    onClose();
                  }}
                />
              </div>
            </div>
          </div>
          <div className={classes.mainButtonsContainer}>
            <Button style={buttonStyle} label="Take to GUI" />
            <Button
              style={buttonStyle}
              label="Duplicate"
              onClick={duplicateAsset}
            />
            <Button
              style={buttonStyle}
              label="Auto Crop"
              onClick={() => setIsAutoCropMode(true)}
            />
            <Button style={buttonStyle} label="Auto Graphics" />
          </div>
        </div>
      )}
    </>
  );
}
