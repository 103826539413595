import React, { useCallback } from 'react';

import { useDropzone } from 'react-dropzone';
// import gql from 'graphql-tag';
// import { compose, graphql } from 'react-apollo';
import { Storage } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
// import { graphqlMutation } from 'aws-appsync-react';
import classnames from 'classnames';

import {
  REACT_APP_REGION,
  REACT_APP_S3_VIDEO_BUCKET,
} from '../../../constants/main';
import classes from './styles.module.scss';
import arrowIcon from '../../../assets/images/arrowDown.svg';
import Video from './Video';

export default function DragAndDropUploader({
  invokeAPUpload,
  onClose,
  onAdd,
  files,
  setFiles,
  setIsUploading,
  multiple,
  isTabInner,
  createProject,
  projectId,
}) {
  const onDrop = useCallback((acceptedFiles) => {
    const filesWithTitlesAndDescriptions = acceptedFiles.map((file) => ({
      file,
      title: file.name,
      description: '',
      uuid: uuidv4(),
      uploadProgress: 0,
    }));
    setFiles(filesWithTitlesAndDescriptions);
  });

  const uploadFiles = async () => {
    if (!files.length) {
      return;
    }

    setIsUploading(true);
    const invokeAPUploadPromises = [];
    const uploadPromises = [];

    console.log('inovkeAPUpload', invokeAPUpload);

    files.forEach((file) => {
      const fileExtension = file.file.name.toLowerCase().split('.');
      console.log(file);
      console.log(invokeAPUpload);

      invokeAPUploadPromises.push(
        invokeAPUpload({
          assetId: file.uuid,
          storyline: file.description,
          title: file.title,
          srcAsset: `${file.uuid}.${fileExtension[fileExtension.length - 1]}`,
          // type: 'raw_video',
        })
      );
    });

    const result = await Promise.all(invokeAPUploadPromises);
    console.log(result);

    Storage.configure({
      AWSS3: {
        bucket: REACT_APP_S3_VIDEO_BUCKET,
        region: REACT_APP_REGION,
        customPrefix: {
          public: '',
        },
      },
    });

    files.forEach((file) => {
      const fileExtension = file.file.name.toLowerCase().split('.');
      uploadPromises.push(
        Storage.put(
          `${file.uuid}.${fileExtension[fileExtension.length - 1]}`,
          file.file,
          {
            progressCallback(progress) {
              const { loaded, total } = progress;
              console.log(`Uploaded: ${loaded}/${total}`);
              setFiles((prevFiles) => {
                const indexOfFile = prevFiles.indexOf(file);
                const newFiles = [...prevFiles];
                newFiles[indexOfFile].uploadProgress = (loaded / total) * 100;
                return newFiles;
              });
            },
            contentType: 'video/*',
          }
        )
      );
    });
    await Promise.all(uploadPromises)
      // eslint-disable-next-line consistent-return
      .then((res) => {
        if (createProject) {
          const idsAsset = [];
          res.forEach((item) => {
            const { key } = item;
            const positionPoint = key.indexOf('.');
            const idAsset = key.slice(0, positionPoint);
            idsAsset.push(idAsset);
          });
          createProject(idsAsset);
        } else if (projectId) {
          onAdd(files.map((file) => file.uuid));
          return onClose();
        }
        if (onAdd) {
          onAdd();
        }
        onClose();
      })
      .catch((error) => {
        console.log(error);
        onAdd();
      });
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.mpg, .mpeg, .mp4, .m4v, .mov, .m2ts',
    multiple,
  });

  return (
    <div className={classes.DragAndDropUploader}>
      {files.length ? (
        <div className={classes.videos}>
          {files.map((file) => (
            <Video
              file={file}
              setState={setFiles}
              state={files}
              key={file.file.name}
            />
          ))}
        </div>
      ) : (
        <div {...getRootProps()} className={classes.uploader}>
          <input {...getInputProps()} />
          <>
            <div className={classes.uploader}>
              <div
                className={classnames(
                  classes.dropZone,
                  isTabInner && classes.isTabInner
                )}
              >
                <img src={arrowIcon} alt="Arrow icon" />
                <span>Drag file</span>
              </div>
            </div>
          </>
        </div>
      )}
      <div className={classes.buttonContainer}>
        <button type="button" onClick={uploadFiles}>
          Upload
        </button>
      </div>
    </div>
  );
}

/* export default compose(
  graphql(listAllAssets, {
    options: {
      fetchPolicy: 'cache-and-network',
    },
    props: (props) => ({
      assets: props.data.listAllAssets ? props.data.listAllAssets.items : [],
    }),
  })
  graphqlMutation(CreateAsset, listAllAssets, 'Asset')
)(DragAndDropUploader);
 */
