import React from 'react';

import Form from 'react-bootstrap/Form';
import classnames from 'classnames';

import classes from './styles.module.scss';

export default function FormField({
  type,
  placeholder,
  value,
  onChange,
  readOnly,
  onBlur,
  isInvalid,
  name,
}) {
  return (
    <Form.Group className={classes.FormField}>
      <Form.Control
        className={classnames(
          classes.formControl,
          isInvalid && classes.isInvalid
        )}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        onBlur={onBlur}
      />
    </Form.Group>
  );
}
