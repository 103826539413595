import React, { useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Link, useHistory } from 'react-router-dom';
import { Card, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Header from '../../../components/Header';
import FormField from '../../../components/Form/FormField';
import FormButton from '../../../components/Form/StyledButton';
import Alert from '../../../components/Alert';

import { UserContext } from '../../../context';

import classes from './styles.module.scss';
import { saveAuthData } from '../../../helpers/authStorage';

const ValidationSchema = Yup.object().shape({
  username: Yup.string().required('Please enter your last name'),
  password: Yup.string().min(8, 'Too Short!').required('Please enter password'),
});

const LoginPage = () => {
  const [, setIsAuth, , setUser] = useContext(UserContext);
  const history = useHistory();
  const [error, setError] = useState(null);
  return (
    <>
      <Header />
      <div className={classes.FormContainer}>
        <Card className={classes.Card}>
          <Card.Body className={classes.CardBody}>
            <Formik
              initialValues={{
                username: '',
                password: '',
              }}
              validationSchema={ValidationSchema}
              onSubmit={(values) => {
                const { username, password } = values;
                Auth.signIn({ username, password })
                  .then((user) => {
                    const { accessToken, refreshToken } =
                      user.signInUserSession;

                    saveAuthData({
                      userId: username,
                      accessToken: accessToken.jwtToken,
                      refreshToken: refreshToken.token,
                    });
                    setIsAuth(!!user);
                    setUser(user);
                  })
                  .then(() => history.push('/dashboard'))
                  .catch((err) => {
                    setError(err.message);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                // isSubmitting,
              }) => (
                <Form className={classes.form} noValidate>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      type="username"
                      placeholder="Username"
                      onChange={handleChange('username')}
                      onBlur={handleBlur('username')}
                      value={values.username}
                      isInvalid={touched.username && errors.username}
                    />
                  </div>
                  <div className={classes.FieldContainer}>
                    <FormField
                      type="password"
                      placeholder="Password"
                      onChange={handleChange('password')}
                      onBlur={handleBlur('password')}
                      value={values.password}
                      isInvalid={touched.password && errors.password}
                    />
                  </div>
                  <div className={classes.boxContainer}>
                    <Link to="/forgot" className={classes.forgotPassword}>
                      Forgot your password?
                    </Link>
                  </div>
                  {/* {error && <FormErrorMessage errors={error} />} */}
                  <div className={classes.formBtnContainer}>
                    <FormButton label="Log in" onClick={handleSubmit} isAuth />
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
        <div className={classes.createAccount}>
          <Link to="/registration" className={classes.createAccountBtn}>
            Create an account.
          </Link>
        </div>
      </div>
      <Alert message={error} clearMessage={() => setError(null)} />
    </>
  );
};

export default LoginPage;
