/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { compose } from 'react-apollo';
import { graphqlMutation } from 'aws-appsync-react';

import RadioButton from '../../Form/RadioButton';
import Button from '../../Form/StyledButton';

import { CreateProject, CreateMultipleTasks } from '../../../graphQL/mutations';

import {
  listUsersProjects,
  getTasksInProgress,
} from '../../../graphQL/queries';

import classes from './styles.module.scss';

function AutoEditForm({ createProject, assets, invokeTaskHandler }) {
  const [duration, setDuration] = useState('6');
  const [videosCount, setVideosCount] = useState('1');
  const [projectName, setProjectName] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [isProjectSet, setIsProjectSet] = useState(false);
  const [videoSettings, setVideoSettings] = useState([]);

  const history = useHistory();

  const changeDuration = (event) => {
    setDuration(event.target.value);
  };

  const changeVideoSettings = (event, id) => {
    const fieldName = event.target.name.slice(0, -1);

    setVideoSettings((prevSettings) => {
      const newSettings = prevSettings.map((setting) => {
        if (setting.id !== id) {
          return setting;
        }
        return {
          ...setting,
          [fieldName]: event.target.value,
        };
      });
      return newSettings;
    });
  };

  const switchToSecondStep = () => {
    if (projectName || projectDescription) {
      const settings = [];
      for (let i = 0; i < +videosCount; i += 1) {
        settings.push({
          id: i,
          pacing: 'fast',
          aspectRatio: '169',
          name: '',
        });
      }
      setVideoSettings(settings);
      setIsProjectSet(true);
    }
  };

  const createTask = (projectId) => {
    const pipelines = videoSettings.map((setting) => {
      let aspectRatio;
      if (setting.aspectRatio === '169') {
        aspectRatio = 16 / 9;
      } else if (setting.aspectRatio === '916') {
        aspectRatio = 9 / 16;
      } else if (setting.aspectRatio === '11') {
        aspectRatio = 1 / 1;
      }
      return {
        assets: assets.map((asset) => asset.assetId),
        taskOrder: ['autoEdit', 'autoCrop'],
        autoEdit: { targetDuration: +duration, pace: setting.pace },
        autoCrop: { aspectRatio },
      };
    });
    console.log(pipelines);
    return invokeTaskHandler({
      projectId,
      callId: uuidv4(),
      pipelines,
    });
  };

  const createAutoEditProject = () => {
    const projectId = uuidv4();
    createProject({
      projectId,
      title: projectName,
      description: projectDescription,
      assets: assets.map((asset) => asset.assetId),
      createdAt: new Date().toISOString(),
    }).then(async () => {
      await createTask(projectId);
      history.push(`/projects`);
      history.push(`/projects/${projectId}`);
    });
  };

  return (
    <div className={classes.AutoEditForm}>
      {!isProjectSet ? (
        <>
          <div className={classes.form}>
            <div className={classes.inputs}>
              <input
                value={projectName}
                onChange={(event) => setProjectName(event.target.value)}
                className={classes.nameInput}
                type="text"
                placeholder="Project Name"
              />
              <textarea
                className={classes.textarea}
                placeholder="Description"
                value={projectDescription}
                onChange={(event) => setProjectDescription(event.target.value)}
              />
            </div>
            <div className={classes.selectors}>
              <div className={classes.selectContainer}>
                <label>
                  Videos to create{' '}
                  <select
                    value={videosCount}
                    onChange={(event) => setVideosCount(event.target.value)}
                  >
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                  </select>
                </label>
              </div>
              <div className={classes.radioButtonsContainer}>
                <span className={classes.title}>Duration</span>
                <RadioButton
                  id="duration1"
                  label="6s"
                  style={{ marginBottom: 10, marginTop: 22 }}
                  name="duration"
                  value="6"
                  isSelected={duration === '6'}
                  changed={changeDuration}
                />
                <RadioButton
                  id="duration2"
                  label="15s"
                  style={{ marginBottom: 10, marginTop: 0 }}
                  name="duration"
                  value="15"
                  isSelected={duration === '15'}
                  changed={changeDuration}
                />
                <RadioButton
                  id="duration3"
                  label="30s"
                  style={{ marginBottom: 10, marginTop: 0 }}
                  name="duration"
                  value="30"
                  isSelected={duration === '30'}
                  changed={changeDuration}
                />
              </div>
            </div>
          </div>
          <div className={classes.buttonContainer}>
            <Button
              onClick={switchToSecondStep}
              label="Create"
              style={{ width: 160, height: 40, borderRadius: 5 }}
            />
          </div>
        </>
      ) : (
        <div className={classes.videoSettingsContainer}>
          {videoSettings.map((setting) => {
            return (
              <div className={classes.videoSettings} key={setting.id}>
                <input
                  type="text"
                  className={classes.nameInput}
                  placeholder="Name"
                  value={setting.name}
                  name={`name${setting.id}`}
                  onChange={(event) => changeVideoSettings(event, setting.id)}
                />
                <div className={classes.selectors}>
                  <div className={classes.pacing}>
                    <span>Pacing</span>
                    <RadioButton
                      id={`pacing1${setting.id}`}
                      label="Fast"
                      style={{ marginBottom: 10, marginTop: 22 }}
                      name={`pacing${setting.id}`}
                      value="fast"
                      isSelected={setting.pacing === 'fast'}
                      changed={(event) =>
                        changeVideoSettings(event, setting.id)
                      }
                    />
                    <RadioButton
                      id={`pacing2${setting.id}`}
                      label="Slow"
                      style={{ marginBottom: 10, marginTop: 0 }}
                      name={`pacing${setting.id}`}
                      value="slow"
                      isSelected={setting.pacing === 'slow'}
                      changed={(event) =>
                        changeVideoSettings(event, setting.id)
                      }
                    />
                  </div>
                  <div className={classes.aspectRatio}>
                    <span>Aspect Ratio</span>
                    <RadioButton
                      id={`aspectRatio1${setting.id}`}
                      label="16:9"
                      style={{ marginBottom: 10, marginTop: 22 }}
                      name={`aspectRatio${setting.id}`}
                      value="169"
                      isSelected={setting.aspectRatio === '169'}
                      changed={(event) =>
                        changeVideoSettings(event, setting.id)
                      }
                    />
                    <RadioButton
                      id={`aspectRatio2${setting.id}`}
                      label="9:16"
                      style={{ marginBottom: 10, marginTop: 0 }}
                      name={`aspectRatio${setting.id}`}
                      value="916"
                      isSelected={setting.aspectRatio === '916'}
                      changed={(event) =>
                        changeVideoSettings(event, setting.id)
                      }
                    />
                    <RadioButton
                      id={`aspectRatio3${setting.id}`}
                      label="1:1"
                      style={{ marginBottom: 10, marginTop: 0 }}
                      name={`aspectRatio${setting.id}`}
                      value="11"
                      isSelected={setting.aspectRatio === '11'}
                      changed={(event) =>
                        changeVideoSettings(event, setting.id)
                      }
                    />
                  </div>
                </div>
              </div>
            );
          })}
          <div className={classes.buttonContainer}>
            <Button
              onClick={createAutoEditProject}
              label="Save"
              style={{ width: 160, height: 40, borderRadius: 5 }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default compose(
  graphqlMutation(CreateMultipleTasks, getTasksInProgress, 'Task'),
  graphqlMutation(CreateProject, listUsersProjects, 'Project')
)(AutoEditForm);
