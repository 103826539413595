/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useRef, useState } from 'react';

import classes from './styles.module.scss';

export default function AssetPreview({ asset }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);

  const videoUrl = asset?.mp4Urls?.length ? asset.mp4Urls[0] : null;
  const videoRef = useRef();

  useEffect(() => {
    const hideOverlay = () => {
      setIsOverlayVisible(false);
    };
    const showOverlay = () => {
      if (videoRef?.current?.seeking) {
        return;
      }
      setIsOverlayVisible(true);
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('playing', hideOverlay);
      videoRef.current.addEventListener('pause', showOverlay);
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('playing', hideOverlay);
        videoRef.current.removeEventListener('pause', showOverlay);
      }
    };
  }, [videoRef]);

  return (
    <div className={classes.AssetPreview}>
      <div
        className={classes.overlay}
        style={{ display: isOverlayVisible ? 'flex' : 'none' }}
        onClick={() => videoRef.current.play()}
      >
        <div className={classes.playIcon} />
      </div>
      <video
        src={videoUrl}
        ref={videoRef}
        onClick={() => videoRef.current.pause()}
      />
    </div>
  );
}
