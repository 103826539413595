import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Card, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Header from '../../../components/Header';
import FormField from '../../../components/Form/FormField';
import FormButton from '../../../components/Form/StyledButton';
import Alert from '../../../components/Alert';

import classes from './styles.module.scss';

const ValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email'),
});

const ForgotPage = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  return (
    <>
      <Header />
      <div className={classes.FormContainer}>
        <Card className={classes.Card}>
          <Card.Body className={classes.cardBody}>
            <div className={classes.descriptionContainer}>
              <div className={classes.title}>Lost Password</div>
              <div className={classes.subTitle}>
                Follow these simple steps to reset your password:
              </div>
              <div className={classes.stepDescription}>
                <div className={classes.item}>
                  1. Enter your Fovea email address.
                </div>
                <div className={classes.item}>
                  2. Wait for your recovery details to be sent
                </div>
                <div className={classes.item}>
                  3. Follow the instructions to generate a new password
                </div>
              </div>
            </div>
            <Formik
              initialValues={{
                email: '',
              }}
              validationSchema={ValidationSchema}
              onSubmit={(values) => {
                const { email } = values;
                const username = email;

                Auth.forgotPassword(username)
                  .then(() => history.push('/confirmCode'))
                  .catch((err) => {
                    setError(err.message);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                // isSubmitting,
              }) => (
                <Form className={classes.form} noValidate>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      type="text"
                      placeholder="Email"
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                      value={values.email}
                      isInvalid={touched.email && errors.email}
                    />
                  </div>
                  <div className={classes.formBtnContainer}>
                    <FormButton label="Submit" onClick={handleSubmit} isAuth />
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </div>
      <Alert message={error} clearMessage={() => setError(null)} />
    </>
  );
};

export default ForgotPage;
