import fileDownload from 'js-file-download';
import axios from 'axios';

export default function downloadFile(videoSrc, asset) {
  axios
    .get(videoSrc, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, asset.title);
    });
}
