/* eslint-disable no-else-return */
/* eslint-disable no-new-func */
import React, { useState, useEffect } from 'react';

import { graphql, compose } from 'react-apollo';
import classnames from 'classnames';
import { graphqlMutation } from 'aws-appsync-react';
import fileDownload from 'js-file-download';
import axios from 'axios';
import gql from 'graphql-tag';

import Asset from '../../components/Content/Asset';
import DragAndDropUploader from '../../components/Content/DragAndDropUploader';
import Modal from '../../modals/ModalWithCloseButton';
import RenderDetails from '../../components/Project/RenderDetails';

import {
  listUsersProjects,
  listParentAssets,
  getCropAssets,
  getDurationAssets,
  getPaceAssets,
} from '../../graphQL/queries';
import {
  CreateAsset,
  DeleteAsset,
  UpdateProject,
  CreateMultipleTasks,
  CreateProject,
} from '../../graphQL/mutations';

import classes from './styles.module.scss';

function Content({
  assets,
  deleteAsset,
  invokeAPUpload,
  client,
  projectId,
  projectAssets,
  updateProject,
  reFetchProject,
  selectedProjectAssets,
  setSelectedProjectAssets,
  invokeTaskHandler,
  createProject,
}) {
  const [areFiltersVisible, setAreFiltersVisible] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState([]);
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [isRenderDetailsPopupVisible, setIsRenderDetailsPopupVisible] =
    useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const toggleFilter = (filter) => {
    /*  setActiveFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        const newFilters = prevFilters.filter((elem) => elem !== filter);
        return newFilters;
      }
      const newFilters = [...prevFilters];
      newFilters.push(filter);
      return newFilters;
    }); */
    setActiveFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return [];
      }
      return [filter];
    });
  };

  console.log(isUploading);

  useEffect(() => {
    if (assets && !projectAssets) {
      setFilteredAssets(assets);
    } else if (projectAssets) {
      setFilteredAssets(projectAssets);
    }
  }, [assets, projectAssets]);

  useEffect(async () => {
    const result = await client.query({
      query: getCropAssets,
      variables: { aspectRatio: 1.7778 },
    });
    console.log(result.data.listAssets.items);
  }, []);
  /* 
  useEffect(() => {
    if (activeFilters.length) {
      const assetsWithSrcMediainfo = projectId
        ? projectAssets.map((asset) => {
            return { ...asset, srcMediainfo: JSON.parse(asset.srcMediainfo) };
          })
        : assets.map((asset) => {
            return { ...asset, srcMediainfo: JSON.parse(asset.srcMediainfo) };
          });
      console.log(projectAssets);

      const aspectRatioFilters = [];
      activeFilters.forEach((filter) => {
        if (filter === 'oneOne') {
          aspectRatioFilters.push(1);
        } else if (filter === 'nineSixteen') {
          aspectRatioFilters.push(9 / 16);
        } else if (filter === 'sixteenNine') {
          aspectRatioFilters.push(16 / 9);
        }
      });

      const durationFilters = [];
      activeFilters.forEach((filter) => {
        if (filter === 'sixSeconds') {
          durationFilters.push(6);
        } else if (filter === 'fifteenSeconds') {
          durationFilters.push(15);
        } else if (filter === 'thirtySeconds') {
          durationFilters.push(30);
        }
      });

      let filterString = 'return ';

      if (aspectRatioFilters.length) {
        aspectRatioFilters.forEach((filter, index) => {
          if (index === 0) {
            filterString += `+asset.srcMediainfo?.video[0].aspectRatio === ${filter}`;
          } else {
            filterString += ` || +asset.srcMediainfo?.video[0].aspectRatio === ${filter}`;
          }
        });
      }

      if (durationFilters.length) {
        durationFilters.forEach((filter, index) => {
          if (index === 0) {
            if (aspectRatioFilters.length) {
              filterString += ` || asset.srcMediainfo?.video[0].duration === ${filter}`;
            } else {
              filterString += `asset.srcMediainfo?.video[0].duration === ${filter}`;
            }
          } else {
            filterString += ` || asset.srcMediainfo?.video[0].duration === ${filter}`;
          }
        });
      }

      const filterFunction = new Function('asset', filterString);
      const assetsWithAppliedFilters =
        assetsWithSrcMediainfo.filter(filterFunction);

      const assetsWithoutSrcMediainfo = assetsWithAppliedFilters.map(
        (asset) => {
          return { ...asset, srcMediainfo: JSON.stringify(asset.srcMediainfo) };
        }
      );
      setFilteredAssets(assetsWithoutSrcMediainfo);
    } else {
      setFilteredAssets(projectId ? projectAssets : assets);
    }
  }, [activeFilters]); */

  useEffect(async () => {
    console.log(activeFilters);
    if (!activeFilters.length) {
      if (assets && !projectAssets) {
        setFilteredAssets(assets);
      } else if (projectAssets) {
        setFilteredAssets(projectAssets);
      }
      return;
    }
    let requestedAssets = [];
    const assetsWithVideo = [];
    const assetsWithoutVideo = [];
    if (
      activeFilters.includes('sixteenNine') ||
      activeFilters.includes('nineSixteen') ||
      activeFilters.includes('oneOne')
    ) {
      let activeCropOption;
      if (activeFilters.includes('sixteenNine')) {
        activeCropOption = 1.7778;
      } else if (activeFilters.includes('nineSixteen')) {
        activeCropOption = 0.5625;
      } else if (activeFilters.includes('oneOne')) {
        activeCropOption = 1;
      }
      const result = await client.query({
        query: getCropAssets,
        variables: { aspectRatio: activeCropOption },
      });
      requestedAssets = result.data.listAssets.items;
    } else if (
      activeFilters.includes('thirtySeconds') ||
      activeFilters.includes('fifteenSeconds') ||
      activeFilters.includes('sixSeconds')
    ) {
      let activeDurationOption;
      if (activeFilters.includes('thirtySeconds')) {
        activeDurationOption = 30;
      } else if (activeFilters.includes('fifteenSeconds')) {
        activeDurationOption = 15;
      } else if (activeFilters.includes('sixSeconds')) {
        activeDurationOption = 6;
      }
      const result = await client.query({
        query: getDurationAssets,
        variables: { autoEditDuration: activeDurationOption },
      });
      requestedAssets = result.data.listAssets.items;
    } else if (
      activeFilters.includes('slow') ||
      activeFilters.includes('fast')
    ) {
      let activePaceOption;
      if (activeFilters.includes('slow')) {
        activePaceOption = 'slow';
      } else if (activeFilters.includes('fast')) {
        activePaceOption = 'fast';
      }
      const result = await client.query({
        query: getPaceAssets,
        variables: { pace: activePaceOption },
      });
      requestedAssets = result.data.listAssets.items;
    }

    requestedAssets.forEach((asset) => {
      if (asset.parentAsset === asset.assetId) {
        assetsWithVideo.push(asset);
      } else {
        assetsWithoutVideo.push(asset);
      }
    });
    console.log(assetsWithoutVideo);
    console.log(assetsWithVideo);

    if (assetsWithoutVideo.length) {
      const promises = [];
      assetsWithoutVideo.forEach((asset) => {
        const assetQuery = gql`
        query getAsset {
          getAsset(assetId: "${asset.parentAsset}") {
            foveaType
            assetId
            parentAsset
            hlsUrl
            title
            description
            aspectRatio
            autoEditDuration
            pace
            srcMediainfo
            mp4Urls
          }
        }
      `;

        promises.push(
          client.query({
            query: assetQuery,
          })
        );
      });

      Promise.all(promises).then((result) => {
        result.forEach((assetData) => {
          if (
            assetsWithVideo.find(
              (asset) => asset.assetId === assetData.data.getAsset.assetId
            )
          ) {
            return;
          }
          assetsWithVideo.push(assetData.data.getAsset);
        });
        setFilteredAssets(assetsWithVideo);
      });
    } else {
      setFilteredAssets(assetsWithVideo);
    }
  }, [activeFilters]);

  const downloadFile = (assetId) => {
    const asset = assets.find((elem) => elem.assetId === assetId);
    const url = asset.mp4Urls ? asset.mp4Urls[0] : null;
    if (!url) {
      return;
    }
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, asset.title);
      });
  };

  const downloadFiles = () => {
    if (selectedProjectAssets) {
      selectedProjectAssets.forEach((assetId) => downloadFile(assetId));
    } else {
      selectedAssets.forEach((assetId) => downloadFile(assetId));
    }
  };

  const deleteAssets = () => {
    const promises = [];
    selectedAssets.forEach((assetId) => {
      promises.push(deleteAsset({ assetId }));
    });
    Promise.all(promises)
      .then(() => {
        client.query({ query: listParentAssets });
        setSelectedAssets([]);
      })
      .catch(() => {
        client.query({ query: listParentAssets });
        setSelectedAssets([]);
      });
  };

  const showUploadModal = () => {
    setIsUploadModalVisible(true);
  };

  const toggleAssetSelection = (assetId) => {
    if (setSelectedProjectAssets) {
      setSelectedProjectAssets((prevAssets) => {
        if (!prevAssets.includes(assetId)) {
          const newSelectedAssets = [...prevAssets, assetId];
          return newSelectedAssets;
        }
        const newSelectedAssets = prevAssets.filter((elem) => elem !== assetId);
        return newSelectedAssets;
      });
    } else {
      setSelectedAssets((prevAssets) => {
        if (!prevAssets.includes(assetId)) {
          const newSelectedAssets = [...prevAssets, assetId];
          return newSelectedAssets;
        }
        const newSelectedAssets = prevAssets.filter((elem) => elem !== assetId);
        return newSelectedAssets;
      });
    }
  };

  const reFetch = () => {
    client.query({ query: listParentAssets });
  };

  const addNewAssetsToProject = (newAssets) => {
    updateProject({
      projectId,
      assets: [...projectAssets, ...newAssets],
    })
      .then(reFetch)
      .then(reFetchProject);
  };

  const hideUploadModal = () => {
    setIsUploadModalVisible(false);
  };

  const showRenderDetailsModal = (asset) => {
    setSelectedAsset(asset);
    setIsRenderDetailsPopupVisible(true);
  };

  const hideRenderDetailsModal = () => {
    setIsRenderDetailsPopupVisible(false);
  };

  const removeAsset = async () => {
    await deleteAsset({ assetId: selectedAsset.assetId });
    client.query({ query: listParentAssets });
  };

  return (
    <div className={classes.Content}>
      <div
        className={classes.header}
        style={{ marginBottom: areFiltersVisible ? 20 : '' }}
      >
        <h1>Content Library</h1>
        {!selectedAssets.length && !selectedProjectAssets?.length ? (
          <div className={classes.btnContainer}>
            {areFiltersVisible ? (
              <button
                type="button"
                className={classes.closeFilters}
                onClick={() => {
                  setAreFiltersVisible(false);
                  setActiveFilters([]);
                }}
              >
                Close Filters
              </button>
            ) : (
              <i
                className={classes.filtersIcon}
                onClick={() => setAreFiltersVisible(true)}
              >
                filters
              </i>
            )}
            <button type="button" onClick={showUploadModal}>
              Upload New Content
            </button>
          </div>
        ) : (
          <div className={classes.btnContainer}>
            <button
              className={classes.outline}
              type="button"
              onClick={deleteAssets}
              style={{ width: 160, marginRight: 22 }}
            >
              Delete
            </button>
            <button
              type="button"
              onClick={downloadFiles}
              style={{ width: 160 }}
            >
              Download
            </button>
          </div>
        )}
      </div>
      {areFiltersVisible && (
        <div className={classes.filters}>
          <i
            className={classnames(
              classes.sixteenNine,
              activeFilters.includes('sixteenNine') && classes.active
            )}
            onClick={() => toggleFilter('sixteenNine')}
          >
            16:9
          </i>
          <i
            className={classnames(
              classes.nineSixteen,
              activeFilters.includes('nineSixteen') && classes.active
            )}
            onClick={() => toggleFilter('nineSixteen')}
          >
            9:16
          </i>
          <i
            className={classnames(
              classes.oneOne,
              activeFilters.includes('oneOne') && classes.active
            )}
            onClick={() => toggleFilter('oneOne')}
          >
            1:1
          </i>
          <i
            className={classnames(
              classes.sixSeconds,
              activeFilters.includes('sixSeconds') && classes.active
            )}
            onClick={() => toggleFilter('sixSeconds')}
          >
            6s
          </i>
          <i
            className={classnames(
              classes.fifteenSeconds,
              activeFilters.includes('fifteenSeconds') && classes.active
            )}
            onClick={() => toggleFilter('fifteenSeconds')}
          >
            15s
          </i>
          <i
            className={classnames(
              classes.thirtySeconds,
              activeFilters.includes('thirtySeconds') && classes.active
            )}
            onClick={() => toggleFilter('thirtySeconds')}
          >
            30s
          </i>
          <i
            className={classnames(
              classes.fast,
              activeFilters.includes('fast') && classes.active
            )}
            onClick={() => toggleFilter('fast')}
          >
            fast
          </i>
          <i
            className={classnames(
              classes.slow,
              activeFilters.includes('slow') && classes.active
            )}
            onClick={() => toggleFilter('slow')}
          >
            slow
          </i>
        </div>
      )}
      <div className={classes.content}>
        {/* {!projectAssets &&
          filteredAssets.map((asset) => {
            return (
              <Asset
                key={asset.assetId}
                asset={asset}
                onDelete={() => deleteAsset({ assetId: asset.assetId })}
                onAdd={() => client.query({ query: listAllAssets })}
                onClick={() => toggleAssetSelection(asset.assetId)}
                selectedAssets={selectedAssets}
              />
            );
          })}
        {projectAssets &&
          projectAssets.map((asset) => {
            return (
              <Asset
                key={asset.assetId}
                asset={asset}
                onDelete={() => deleteAsset({ assetId: asset.assetId })}
                onAdd={() => client.query({ query: listAllAssets })}
                onClick={() => toggleAssetSelection(asset.assetId)}
                selectedAssets={selectedProjectAssets || selectedAssets}
              />
            );
          })} */}
        {filteredAssets.map((asset) => {
          return (
            <Asset
              key={asset.assetId}
              asset={asset}
              onDelete={deleteAsset}
              onAdd={() => client.query({ query: listParentAssets })}
              onClick={() => showRenderDetailsModal(asset)}
              onSelect={() => toggleAssetSelection(asset.assetId)}
              selectedAssets={selectedProjectAssets || selectedAssets}
              onDownload={() => downloadFile(asset.assetId)}
            />
          );
        })}
      </div>
      <Modal open={isUploadModalVisible} onClose={hideUploadModal}>
        <DragAndDropUploader
          onClose={() => {
            hideUploadModal();
            setFiles([]);
          }}
          invokeAPUpload={invokeAPUpload}
          onAdd={projectId ? addNewAssetsToProject : reFetch}
          files={files}
          projectId={projectId}
          setFiles={setFiles}
          setIsUploading={setIsUploading}
          reFetchProject={reFetchProject}
        />
      </Modal>
      <Modal
        open={isRenderDetailsPopupVisible}
        onClose={hideRenderDetailsModal}
      >
        <RenderDetails
          currentAsset={selectedAsset}
          assets={assets}
          onDeleteAsset={removeAsset}
          onClose={hideRenderDetailsModal}
          projectId={projectId || null}
          reFetchRenders={reFetch}
          invokeTaskHandler={invokeTaskHandler}
          createProject={createProject}
          client={client}
        />
      </Modal>
    </div>
  );
}

export default compose(
  graphql(listParentAssets, {
    options: {
      fetchPolicy: 'network-only',
    },
    props: (props) => ({
      assets: props.data.listAssets ? props.data.listAssets.items : [],
    }),
  }),
  graphqlMutation(DeleteAsset, listParentAssets, 'Asset'),
  graphqlMutation(CreateAsset, listParentAssets, 'Asset'),
  graphqlMutation(UpdateProject, listUsersProjects, 'Project'),
  graphqlMutation(CreateMultipleTasks, null, 'Task'),
  graphqlMutation(CreateProject, null, 'Project')
)(Content);
