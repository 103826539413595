import React, { useState } from 'react';
import classes from './styles.module.scss';

import Modal from '../../modals/ModalWithCloseButton';
import AutoEditForm from '../../components/Dashboard/AutoEditForm';
import AutoCropForm from '../../components/Dashboard/AutoCropForm';
import AutoSearchForm from '../../components/Dashboard/AutoSearchForm';

const DashboardPage = () => {
  const [showAutoEdit, setShowAutoEdit] = useState(false);
  const [showAutoCrop, setShowAutoCrop] = useState(false);
  const [isAutoSearchModalVisible, setIsAutoSearchModalVisible] =
    useState(false);

  const handleClose = () => {
    setShowAutoEdit(false);
    setShowAutoCrop(false);
  };
  const handleShowEdit = () => setShowAutoEdit(true);
  const handleShowCrop = () => setShowAutoCrop(true);
  const showAutoSearchModal = () => {
    setIsAutoSearchModalVisible(true);
  };

  const hideAutoSearchModal = () => {
    setIsAutoSearchModalVisible(false);
  };
  return (
    <>
      <div className={classes.DashboardPage}>
        <div className={classes.container}>
          <div className={classes.item} onClick={handleShowCrop}>
            <div className={classes.boxImg}>
              <i className={classes.autoCropIcon}>autoCrop</i>
            </div>
            <div className={classes.itemText}>Auto Crop</div>
          </div>
          <div className={classes.item} onClick={handleShowEdit}>
            <div className={classes.boxImg}>
              <i className={classes.autoEditIcon}>autoEdit</i>
            </div>
            <div className={classes.itemText}>Auto Edit</div>
          </div>
          <div className={classes.item} onClick={showAutoSearchModal}>
            <div className={classes.boxImg}>
              <i className={classes.searchIcon}>search</i>
            </div>
            <div className={classes.itemText}>Search</div>
          </div>
          <div className={classes.item}>
            <div className={classes.boxImg}>
              <i className={classes.autoGraphicsIcon}>autoGraphics</i>
            </div>
            <div className={classes.itemText}>Auto Graphics</div>
          </div>
        </div>
      </div>
      <Modal open={showAutoEdit} onClose={handleClose}>
        <AutoEditForm onClose={handleClose} />
      </Modal>
      <Modal open={showAutoCrop} onClose={handleClose}>
        <AutoCropForm onClose={handleClose} />
      </Modal>
      <Modal open={isAutoSearchModalVisible} onClose={hideAutoSearchModal}>
        <AutoSearchForm onClose={hideAutoSearchModal} />
      </Modal>
    </>
  );
};

export default DashboardPage;
