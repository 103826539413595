/* eslint-disable no-new-func */

import React, { useState, useEffect } from 'react';

import { graphql, compose } from 'react-apollo';
import classnames from 'classnames';
import { graphqlMutation } from 'aws-appsync-react';
import gql from 'graphql-tag';

import Asset from '../Content/Asset';
import DragAndDropUploader from '../Content/DragAndDropUploader';
import Modal from '../../modals/ModalWithCloseButton';
import RenderDetails from '../Project/RenderDetails';

import classes from './styles.module.scss';
import {
  listAllAssets,
  getCropAssets,
  getDurationAssets,
  getPaceAssets,
} from '../../graphQL/queries';
import { DeleteAsset, CreateMultipleTasks } from '../../graphQL/mutations';
import downloadFile from '../../helpers/downloadFile';

function Content({
  assets,
  isRenders,
  isContent,
  label,
  projectId,
  deleteAsset,
  client,
  invokeTaskHandler,
}) {
  const [areFiltersVisible, setAreFiltersVisible] = useState(false);
  const [activeFilters, setActiveFilters] = useState([]);
  const [isUploadModalVisible, setIsUploadModalVisible] = useState(false);
  const [isRenderModalVisible, setIsRenderModalVisible] = useState(false);
  const [renders, setRenders] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const toggleFilter = (filter) => {
    /* setActiveFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        const newFilters = prevFilters.filter((elem) => elem !== filter);
        return newFilters;
      }
      const newFilters = [...prevFilters];
      newFilters.push(filter);
      return newFilters;
    }); */
    setActiveFilters((prevFilters) => {
      if (prevFilters.includes(filter)) {
        return [];
      }
      return [filter];
    });
  };

  const showUploadModal = () => {
    setIsUploadModalVisible(true);
  };

  const hideUploadModal = () => {
    setIsUploadModalVisible(false);
  };

  const showRenderModal = () => {
    setIsRenderModalVisible(true);
  };

  const hideRenderModal = () => {
    setIsRenderModalVisible(false);
  };

  const removeAsset = async () => {
    await deleteAsset({ assetId: selectedAsset.assetId });
    client.query({ query: listAllAssets });
  };

  const filterRendersByProject = () => {
    console.log('assets', assets);
    const rndrs = assets.filter((asset) => {
      return (
        asset?.projects?.includes(projectId) && asset.foveaType === 'auto-edit'
      );
    });

    const filteredRenders = [];
    const parentAssetIds = [];
    rndrs.forEach((rndr) => {
      if (!parentAssetIds.includes(rndr.parent_asset)) {
        filteredRenders.push(rndr);
        parentAssetIds.push(rndr.parent_asset);
      }
    });
    return filteredRenders;
  };

  const reFetchRenders = () => {
    client.query({
      query: listAllAssets,
    });
  };

  /* useEffect(() => {
    if (assets.length) {
      setRenders(filterRendersByProject);
    }
  }, [assets]);

  useEffect(() => {
    if (activeFilters.length) {
      const assetsWithSrcMediainfo = filterRendersByProject().map((asset) => {
        return { ...asset, srcMediainfo: JSON.parse(asset.srcMediainfo) };
      });

      const aspectRatioFilters = [];
      activeFilters.forEach((filter) => {
        if (filter === 'oneOne') {
          aspectRatioFilters.push(1);
        } else if (filter === 'nineSixteen') {
          aspectRatioFilters.push(9 / 16);
        } else if (filter === 'sixteenNine') {
          aspectRatioFilters.push(16 / 9);
        }
      });

      const durationFilters = [];
      activeFilters.forEach((filter) => {
        if (filter === 'sixSeconds') {
          durationFilters.push(6);
        } else if (filter === 'fifteenSeconds') {
          durationFilters.push(15);
        } else if (filter === 'thirtySeconds') {
          durationFilters.push(30);
        }
      });

      let filterString = 'return ';

      if (aspectRatioFilters.length) {
        aspectRatioFilters.forEach((filter, index) => {
          if (index === 0) {
            filterString += `+asset.srcMediainfo.video[0].aspectRatio === ${filter}`;
          } else {
            filterString += ` || +asset.srcMediainfo.video[0].aspectRatio === ${filter}`;
          }
        });
      }

      if (durationFilters.length) {
        durationFilters.forEach((filter, index) => {
          if (index === 0) {
            if (aspectRatioFilters.length) {
              filterString += ` || asset.srcMediainfo.video[0].duration === ${filter}`;
            } else {
              filterString += `asset.srcMediainfo.video[0].duration === ${filter}`;
            }
          } else {
            filterString += ` || asset.srcMediainfo.video[0].duration === ${filter}`;
          }
        });
      }

      const filterFunction = new Function('asset', filterString);
      const assetsWithAppliedFilters =
        assetsWithSrcMediainfo.filter(filterFunction);

      const assetsWithoutSrcMediainfo = assetsWithAppliedFilters.map(
        (asset) => {
          return { ...asset, srcMediainfo: JSON.stringify(asset.srcMediainfo) };
        }
      );
      setRenders(assetsWithoutSrcMediainfo);
    } else {
      setRenders(filterRendersByProject);
    }
  }, [activeFilters]); */

  useEffect(async () => {
    if (!activeFilters.length) {
      setRenders(filterRendersByProject());
      return;
    }
    let requestedAssets = [];
    const assetsWithVideo = [];
    const assetsWithoutVideo = [];
    if (
      activeFilters.includes('sixteenNine') ||
      activeFilters.includes('nineSixteen') ||
      activeFilters.includes('oneOne')
    ) {
      let activeCropOption;
      if (activeFilters.includes('sixteenNine')) {
        activeCropOption = 1.7778;
      } else if (activeFilters.includes('nineSixteen')) {
        activeCropOption = 0.5625;
      } else if (activeFilters.includes('oneOne')) {
        activeCropOption = 1;
      }
      const result = await client.query({
        query: getCropAssets,
        variables: { aspectRatio: activeCropOption },
      });
      requestedAssets = result.data.listAssets.items;
    } else if (
      activeFilters.includes('thirtySeconds') ||
      activeFilters.includes('fifteenSeconds') ||
      activeFilters.includes('sixSeconds')
    ) {
      let activeDurationOption;
      if (activeFilters.includes('thirtySeconds')) {
        activeDurationOption = 30;
      } else if (activeFilters.includes('fifteenSeconds')) {
        activeDurationOption = 15;
      } else if (activeFilters.includes('sixSeconds')) {
        activeDurationOption = 6;
      }
      const result = await client.query({
        query: getDurationAssets,
        variables: { autoEditDuration: activeDurationOption },
      });
      requestedAssets = result.data.listAssets.items;
    } else if (
      activeFilters.includes('slow') ||
      activeFilters.includes('fast')
    ) {
      let activePaceOption;
      if (activeFilters.includes('slow')) {
        activePaceOption = 'slow';
      } else if (activeFilters.includes('fast')) {
        activePaceOption = 'fast';
      }
      const result = await client.query({
        query: getPaceAssets,
        variables: { pace: activePaceOption },
      });
      requestedAssets = result.data.listAssets.items;
    }

    requestedAssets.forEach((asset) => {
      if (asset.parentAsset === asset.assetId) {
        assetsWithVideo.push(asset);
      } else {
        assetsWithoutVideo.push(asset);
      }
    });
    console.log(assetsWithoutVideo);
    console.log(assetsWithVideo);

    if (assetsWithoutVideo.length) {
      const promises = [];
      assetsWithoutVideo.forEach((asset) => {
        const assetQuery = gql`
        query getAsset {
          getAsset(assetId: "${asset.parentAsset}") {
            foveaType
            assetId
            parentAsset
            hlsUrl
            title
            description
            aspectRatio
            autoEditDuration
            pace
            srcMediainfo
            mp4Urls
          }
        }
      `;

        promises.push(
          client.query({
            query: assetQuery,
          })
        );
      });

      Promise.all(promises).then((result) => {
        result.forEach((assetData) => {
          if (
            assetsWithVideo.find(
              (asset) => asset.assetId === assetData.data.getAsset.assetId
            ) ||
            assetData.data.getAsset.foveaType !== 'auto-edit' ||
            !assetData.data.getAsset?.projects?.includes(projectId)
          ) {
            return;
          }
          assetsWithVideo.push(assetData.data.getAsset);
        });
        setRenders(assetsWithVideo);
      });
    } else {
      setRenders(assetsWithVideo);
    }
  }, [activeFilters]);

  return (
    <div className={classes.Content}>
      <div
        className={classes.header}
        style={{ marginBottom: areFiltersVisible ? 20 : '' }}
      >
        <h1>{label}</h1>
        <div className={classes.btnContainer}>
          {areFiltersVisible ? (
            <button
              type="button"
              className={classnames(
                classes.closeFilters,
                isRenders && classes.isRenders
              )}
              onClick={() => {
                setAreFiltersVisible(false);
                setActiveFilters([]);
              }}
            >
              Close Filters
            </button>
          ) : (
            <i
              className={classes.filtersIcon}
              onClick={() => setAreFiltersVisible(true)}
            >
              filters
            </i>
          )}
          {isContent ? (
            <button type="button" onClick={showUploadModal}>
              Upload New Content
            </button>
          ) : null}
        </div>
      </div>
      {areFiltersVisible && isRenders && (
        <div className={classes.filters}>
          <i
            className={classnames(
              classes.sixteenNine,
              activeFilters.includes('sixteenNine') && classes.active
            )}
            onClick={() => toggleFilter('sixteenNine')}
          >
            16:9
          </i>
          <i
            className={classnames(
              classes.nineSixteen,
              activeFilters.includes('nineSixteen') && classes.active
            )}
            onClick={() => toggleFilter('nineSixteen')}
          >
            9:16
          </i>
          <i
            className={classnames(
              classes.oneOne,
              activeFilters.includes('oneOne') && classes.active
            )}
            onClick={() => toggleFilter('oneOne')}
          >
            1:1
          </i>
          <i
            className={classnames(
              classes.sixSeconds,
              activeFilters.includes('sixSeconds') && classes.active
            )}
            onClick={() => toggleFilter('sixSeconds')}
          >
            6s
          </i>
          <i
            className={classnames(
              classes.fifteenSeconds,
              activeFilters.includes('fifteenSeconds') && classes.active
            )}
            onClick={() => toggleFilter('fifteenSeconds')}
          >
            15s
          </i>
          <i
            className={classnames(
              classes.thirtySeconds,
              activeFilters.includes('thirtySeconds') && classes.active
            )}
            onClick={() => toggleFilter('thirtySeconds')}
          >
            30s
          </i>
          <i
            className={classnames(
              classes.fast,
              activeFilters.includes('fast') && classes.active
            )}
            onClick={() => toggleFilter('fast')}
          >
            fast
          </i>
          <i
            className={classnames(
              classes.slow,
              activeFilters.includes('slow') && classes.active
            )}
            onClick={() => toggleFilter('slow')}
          >
            slow
          </i>
        </div>
      )}
      {areFiltersVisible && isContent && (
        <div
          className={classnames(
            classes.filters,
            isContent && classes.isContent
          )}
        >
          <i
            className={classnames(
              classes.sixteenNine,
              activeFilters.includes('sixteenNine') && classes.active
            )}
            onClick={() => toggleFilter('sixteenNine')}
          >
            16:9
          </i>
          <i
            className={classnames(
              classes.nineSixteen,
              activeFilters.includes('nineSixteen') && classes.active
            )}
            onClick={() => toggleFilter('nineSixteen')}
          >
            9:16
          </i>
          <i
            className={classnames(
              classes.oneOne,
              activeFilters.includes('oneOne') && classes.active
            )}
            onClick={() => toggleFilter('oneOne')}
          >
            1:1
          </i>
        </div>
      )}
      <div className={classes.content}>
        {renders.map((render) => {
          return (
            <Asset
              onDownload={downloadFile}
              key={render.assetId}
              asset={render}
              onClick={() => {
                setSelectedAsset(render);
                showRenderModal();
              }}
            />
          );
        })}
      </div>
      <Modal open={isUploadModalVisible} onClose={hideUploadModal}>
        <DragAndDropUploader />
      </Modal>
      <Modal open={isRenderModalVisible} onClose={hideRenderModal}>
        <RenderDetails
          client={client}
          currentAsset={selectedAsset}
          assets={assets}
          onDeleteAsset={removeAsset}
          onClose={hideRenderModal}
          projectId={projectId}
          reFetchRenders={reFetchRenders}
          invokeTaskHandler={invokeTaskHandler}
        />
      </Modal>
    </div>
  );
}

export default compose(
  graphql(listAllAssets, {
    options: {
      fetchPolicy: 'network-only',
    },
    props: (props) => ({
      assets: props.data.listAssets ? props.data.listAssets.items : [],
    }),
  }),
  graphqlMutation(DeleteAsset, listAllAssets, 'Asset'),
  graphqlMutation(CreateMultipleTasks, null, 'Task')
)(Content);
