/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from 'react';

import classes from './styles.module.scss';

export default function Video({ file, setState, state }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const videoRef = useRef();
  const blobUrl = URL.createObjectURL(file.file);

  const changeValue = (event, fieldName) => {
    const indexOfFile = state.indexOf(file);
    const newFiles = [...state];
    newFiles[indexOfFile][fieldName] = event.target.value;
    setState(newFiles);
  };

  return (
    <div className={classes.Video}>
      <div className={classes.preview}>
        <div
          className={classes.overlay}
          style={{ opacity: isOverlayVisible ? 1 : 0 }}
          onClick={() => {
            if (videoRef.current.paused) {
              videoRef.current.play();
            } else {
              videoRef.current.pause();
            }
            setIsOverlayVisible((prevState) => !prevState);
          }}
        >
          <button type="button">Play</button>
        </div>
        <video ref={videoRef}>
          <source src={blobUrl} />
        </video>
      </div>
      <div className={classes.inputs}>
        <input
          type="text"
          placeholder="Title"
          value={file.title}
          onChange={(event) => changeValue(event, 'title')}
        />
        <textarea
          placeholder="Description"
          value={file.description}
          onChange={(event) => changeValue(event, 'description')}
        />
      </div>
    </div>
  );
}
