import React, { useState } from 'react';
import classnames from 'classnames';

import SearchForm from '../../Dashboard/SearchForm';

import StyledButton from '../../Form/StyledButton';

import classes from './styles.module.scss';

const CreateProjectsForm = ({ onClose, createItem, onAdd }) => {
  const [state, setState] = useState({
    name: '',
    description: '',
  });
  const [page, setPage] = useState('project');

  const onChangeHandler = (e) => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const createProject = (idsAsset) => {
    createItem(state, idsAsset);
  };

  return (
    <>
      <div className={classes.CreateProject}>
        {page === 'project' ? (
          <>
            <div className={classes.projectContainer}>
              <form className={classes.form}>
                <div className={classes.fieldContainer}>
                  <input
                    name="name"
                    value={state.name}
                    type="text"
                    placeholder="Project Name"
                    className={classes.formInput}
                    onChange={onChangeHandler}
                  />
                </div>
                <div className={classes.fieldContainer}>
                  <textarea
                    name="description"
                    value={state.description}
                    placeholder="Project Description"
                    className={classnames(
                      classes.formInput,
                      classes.formDescription
                    )}
                    onChange={onChangeHandler}
                  />
                </div>
              </form>
              <div className={classes.btnContainer}>
                <StyledButton
                  label="Next"
                  isForm
                  onClick={() => setPage('upload')}
                />
              </div>
            </div>
          </>
        ) : (
          <SearchForm
            onAdd={onAdd}
            onClose={onClose}
            createProject={createProject}
          />
        )}
      </div>
    </>
  );
};

export default CreateProjectsForm;
