import React from 'react';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import classnames from 'classnames';
import * as Yup from 'yup';

import CardNumberField from '../../components/Form/CardNumberField';
import ExpCardDateField from '../../components/Form/ExpCardDateField';
import FormField from '../../components/Form/FormField';
import FormButton from '../../components/Form/StyledButton';
import classes from './styles.module.scss';

const ValidationSchema = Yup.object().shape({
  name: Yup.string(),
  username: Yup.string().required('Please enter your last name'),
  password: Yup.string().min(8, 'Too Short!').required('Please enter password'),
});

const digitsAndSpacesOnly = (value) => /[0-9 ]+/.test(value);
const digitsOnly = (value) => /^\d+$/.test(value);

const ValidationSchemaCard = Yup.object().shape({
  nameCard: Yup.string().required(),
  cardNumber: Yup.string()
    .required('Please enter card number')
    .test(
      'Digits only',
      'The field should have digits only',
      digitsAndSpacesOnly
    )
    .length(19),
  expDate: Yup.string()
    .required('Please enter card expiration date')
    .test(
      'Digits only',
      'The field should have digits only',
      digitsAndSpacesOnly
    )
    .length(7),
  cvc: Yup.string()
    .required(`Please enter card's cvv`)
    .test('Digits only', 'The field should have digits only', digitsOnly)
    .length(3),
});

const Settings = () => {
  return (
    <>
      <div className={classes.SettingsPage}>
        <div className={classes.container}>
          <div className={classes.section}>
            <div className={classnames(classes.header, classes.topHeader)}>
              <div className={classes.title}>Profile</div>
              <div className={classes.boxIcon}>
                <i className={classes.pencilIcon}>pencil</i>
              </div>
            </div>
            <div className={classes.formContainer}>
              <Formik
                initialValues={{
                  name: '',
                  username: '',
                  password: '',
                }}
                validationSchema={ValidationSchema}
                onSubmit={(values) => {
                  console.log(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  /* handleSubmit, */
                  // isSubmitting,
                }) => (
                  <Form className={classes.form} noValidate>
                    <div className={classes.formFieldContainer}>
                      <FormField
                        type="text"
                        placeholder="Name"
                        onChange={handleChange('name')}
                        onBlur={handleBlur('name')}
                        value={values.name}
                        isInvalid={touched.name && errors.name}
                      />
                    </div>
                    <div className={classes.formFieldContainer}>
                      <FormField
                        type="username"
                        placeholder="Username"
                        onChange={handleChange('username')}
                        onBlur={handleBlur('username')}
                        value={values.username}
                        isInvalid={touched.username && errors.username}
                      />
                    </div>
                    <div className={classes.formFieldContainer}>
                      <FormField
                        type="password"
                        placeholder="Password"
                        onChange={handleChange('password')}
                        onBlur={handleBlur('password')}
                        value={values.password}
                        isInvalid={touched.password && errors.password}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className={classes.section}>
            <div className={classes.header}>
              <div className={classes.title}>Profile Picture</div>
              <div className={classes.boxIcon}>
                <i className={classes.pencilIcon}>pencil</i>
              </div>
            </div>
          </div>
          <div className={classes.section}>
            <div className={classes.header}>
              <div className={classes.title}>Billings Information</div>
              <div className={classes.boxIcon}>
                <i className={classes.pencilIcon}>pencil</i>
              </div>
            </div>
            <div className={classes.formContainer}>
              <Formik
                initialValues={{
                  nameCard: '',
                  cardNumber: '',
                  exDate: '',
                  cvc: '',
                }}
                validationSchema={ValidationSchemaCard}
                onSubmit={(values) => {
                  console.log(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  /* handleSubmit, */
                  // isSubmitting,
                }) => (
                  <Form className={classes.form} noValidate>
                    <div className={classes.formFieldContainer}>
                      <FormField
                        type="text"
                        placeholder="Name on Card"
                        onChange={handleChange('nameCard')}
                        onBlur={handleBlur('nameCard')}
                        value={values.nameCard}
                        isInvalid={touched.nameCard && errors.nameCard}
                      />
                    </div>
                    <div className={classes.formFieldContainer}>
                      <CardNumberField
                        type="text"
                        placeholder="Card Number"
                        value={values.cardNumber}
                        onChange={handleChange('cardNumber')}
                        onBlur={handleBlur('cardNumber')}
                        isInvalid={touched.cardNumber && errors.cardNumber}
                      />
                    </div>
                    <div
                      className={classnames(
                        classes.formFieldContainer,
                        classes.boxToField
                      )}
                    >
                      <div className={classes.exDate}>
                        <ExpCardDateField
                          type="text"
                          placeholder="Ex. date"
                          value={values.expDate}
                          onChange={handleChange('expDate')}
                          onBlur={handleBlur('expDate')}
                          isInvalid={touched.expDate && errors.expDate}
                        />
                      </div>
                      <div className={classes.cvv}>
                        <FormField
                          type="text"
                          placeholder="CVC"
                          value={values.cvc}
                          onChange={handleChange('cvc')}
                          onBlur={handleBlur('cvc')}
                          isInvalid={touched.cvc && errors.cvc}
                        />
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div className={classes.section}>
            <div className={classes.header}>
              <div className={classes.title}>Organization</div>
              <div className={classes.boxIcon}>
                <i className={classes.pencilIcon}>pencil</i>
              </div>
            </div>
            <div className={classes.formContainer}>
              <Formik
                initialValues={{
                  affiliation: '',
                }}
                onSubmit={(values) => {
                  console.log(values);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  /* handleSubmit, */
                  // isSubmitting,
                }) => (
                  <Form className={classes.form} noValidate>
                    <div className={classes.formFieldContainer}>
                      <FormField
                        type="text"
                        placeholder="Affiliation"
                        onChange={handleChange('nameCard')}
                        onBlur={handleBlur('nameCard')}
                        value={values.affiliation}
                        isInvalid={touched.affiliation && errors.affiliation}
                      />
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <div>
            <div className={classes.btnContainer}>
              <FormButton label="Delete Account" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
