import React from 'react';
import ReactDOM from 'react-dom';
import { Auth } from 'aws-amplify';
import { AWSAppSyncClient } from 'aws-appsync';
/* import {
  ApolloProvider,
  ApolloClient,
  // defaultDataIdFromObject,
  // InMemoryCache,
} from '@apollo/client'; */
import { ApolloProvider } from 'react-apollo';
import { Rehydrated } from 'aws-appsync-react';
import * as localForage from 'localforage';

import reportWebVitals from './reportWebVitals';

import {
  REACT_APP_API_URL,
  REACT_APP_REGION,
  REACT_APP_AUTH_API_KEY,
  REACT_APP_AUTH_TYPE,
} from './constants/main';

/* import Routes from './routes'; */
import App from './App';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';

const appsyncClient = new AWSAppSyncClient(
  {
    url: REACT_APP_API_URL,
    region: REACT_APP_REGION,
    auth: {
      type: REACT_APP_AUTH_TYPE,
      apiKey: REACT_APP_AUTH_API_KEY,
      jwtToken: async () =>
        (await Auth.currentSession()).getAccessToken().getJwtToken(),
    },
    complexObjectsCredentials: () => Auth.currentCredentials(),
    offlineConfig: {
      storage: localForage,
    },
    /* cacheOptions: {
      dataIdFromObject: (obj) => {
        const id = defaultDataIdFromObject(obj);

        if (!id) {
          const { __typename: typename } = obj;
          switch (typename) {
            case 'Comment':
              return `${typename}:${obj.commentId}`;
            default:
              return id;
          }
        }
        return id;
      },
    }, */
  },
  {
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only', // <-- HERE: check the apollo fetch policy options
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      },
    },
  }
);

/* const client = new ApolloClient({
  url: REACT_APP_API_URL,
  region: REACT_APP_REGION,
  auth: {
    type: REACT_APP_AUTH_TYPE,
    apiKey: REACT_APP_AUTH_API_KEY,
    jwtToken: async () =>
      (await Auth.currentSession()).getAccessToken().getJwtToken(),
  },
  complexObjectsCredentials: () => Auth.currentCredentials(),
  offlineConfig: {
    storage: localForage,
  },
  cache: InMemoryCache({
    dataIdFromObject: (obj) => {
      const id = defaultDataIdFromObject(obj);

      if (!id) {
        const { __typename: typename } = obj;
        switch (typename) {
          case 'Comment':
            return `${typename}:${obj.commentId}`;
          default:
            return id;
        }
      }
      return id;
    },
  }),
}); */

const WithProvider = () => (
  <ApolloProvider client={appsyncClient}>
    <Rehydrated>
      <App />
    </Rehydrated>
  </ApolloProvider>
);

ReactDOM.render(<WithProvider />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
