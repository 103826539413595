import React, { useState, useEffect } from 'react';
import { graphql, compose } from 'react-apollo';
import { graphqlMutation } from 'aws-appsync-react';
import { Tabs, Tab } from 'react-bootstrap';
import classnames from 'classnames';

import { CreateAsset } from '../../../graphQL/mutations';
import { listAllAssets } from '../../../graphQL/queries';

import DragAndDropUploader from '../../Content/DragAndDropUploader';
import Video from '../../Projects/VideoWithCheckBox';

import StyledButton from '../../Form/StyledButton';

import classes from './styles.module.scss';

const SearchForm = ({
  invokeAPUpload,
  onAdd,
  onClose,
  createProject,
  assets,
}) => {
  const [files, setFiles] = useState([]);
  const [key, setKey] = useState('upload');
  const [isUploading, setIsUploading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [selectedAssets, setSelectedAssets] = useState([]);

  console.log(isUploading);
  console.log(files);

  useEffect(() => {
    if (assets) {
      setFilteredAssets(assets);
    }
  }, [assets]);

  const filterAssets = () => {
    if (searchTerm.trim() === '') {
      setFilteredAssets(assets);
    }
    const updatedAssets = assets.filter((asset) =>
      asset.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );
    setFilteredAssets(updatedAssets);
  };

  return (
    <div
      className={classnames(
        classes.SearchForm,
        key === 'upload' && classes.uploadTab
      )}
    >
      <Tabs
        activeKey={key}
        onSelect={(k) => setKey(k)}
        id="uncontrolled-tab-example"
        className={classnames(
          classes.tabsContainer,
          files.length && classes.isFiles
        )}
      >
        <Tab
          eventKey="upload"
          title="New file"
          className={classnames(
            classes.TabItem,
            files.length && classes.isFiles
          )}
        >
          <DragAndDropUploader
            isTabInner
            files={files}
            setFiles={setFiles}
            invokeAPUpload={invokeAPUpload}
            setIsUploading={setIsUploading}
            onAdd={onAdd}
            onClose={onClose}
            createProject={createProject}
          />
        </Tab>
        <Tab
          eventKey="existing"
          title="Existing"
          className={classnames(
            classes.TabItem,
            files.length && classes.isFiles
          )}
        >
          <div className={classes.existingContainer}>
            <form className={classes.searchForm}>
              <div className={classes.searchFormContainer}>
                <input
                  type="text"
                  placeholder="Search"
                  className={classes.searchFormInput}
                  value={searchTerm}
                  onChange={(event) => setSearchTerm(event.target.value)}
                />
              </div>
              <div className={classes.searchContainer}>
                <StyledButton label="Search" isSearch onClick={filterAssets} />
              </div>
            </form>
            <div className={classes.searchResultBox}>
              {filteredAssets.map((asset, index) => (
                <Video
                  index={index}
                  asset={asset}
                  key={asset.assetId}
                  setSelectedAssets={setSelectedAssets}
                  selectedAssets={selectedAssets}
                />
              ))}
            </div>
          </div>
        </Tab>
      </Tabs>
      <div className={classes.btnContainer}>
        {key === 'existing' && (
          <StyledButton
            onClick={() => {
              createProject(selectedAssets);
              onClose();
            }}
            label="Add"
            isForm
          />
        )}
      </div>
    </div>
  );
};

export default compose(
  graphql(listAllAssets, {
    options: {
      fetchPolicy: 'network-only',
    },
    props: (props) => ({
      assets: props.data.listAssets ? props.data.listAssets.items : [],
    }),
  }),

  graphqlMutation(CreateAsset, listAllAssets, 'Asset')
)(SearchForm);
