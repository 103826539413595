import React, { useRef } from 'react';

import { Portal } from '../../components/Portal';

import classes from './styles.module.scss';

export default function ModalWithCloseButton({ open, onClose, children }) {
  const backdrop = useRef(null);
  if (!open) {
    return null;
  }

  const onClick = (e) => {
    if (backdrop.current === e.target) {
      onClose();
    }
  };

  return (
    <Portal>
      <div className={classes.backdrop} ref={backdrop} onClick={onClick}>
        <div className={classes.content}>
          <div className={classes.modalHeader}>
            <i className={classes.closeIcon} onClick={onClose}>
              close
            </i>
          </div>
          {children}
        </div>
      </div>
    </Portal>
  );
}
