/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import classes from './styles.module.scss';

export default function Checkbox({
  id,
  onChange,
  label,
  name,
  isChecked,
  dataId,
}) {
  return (
    <label htmlFor={id} className={classes.label}>
      {label}
      <label className={classes.container}>
        <input
          data-id={dataId}
          type="checkbox"
          id={id}
          checked={isChecked}
          name={name}
          onChange={onChange}
        />
        <span className={classes.checkmark} />
      </label>
    </label>
  );
}
