/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';

import classnames from 'classnames';

import classes from './styles.module.scss';

export default function Asset({
  asset = '',
  selectedAssets = [],
  onClick,
  onDelete,
  onDownload,
  onSelect,
}) {
  const [isHoverMenuVisible, setIsHoverMenuVisible] = useState(false);
  const url = asset.mp4Urls ? asset.mp4Urls[0] : null;
  let duration = asset.srcMediainfo
    ? JSON.parse(asset.srcMediainfo).container.duration
    : null;
  if (duration) {
    let minutes = Math.floor(duration / 60);
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    let seconds = Math.round(duration % 60);
    if (seconds < 10) {
      seconds = `0${seconds}`;
    }
    duration = `${minutes}:${seconds}`;
  }
  const aspectRatio = asset.srcMediainfo
    ? +JSON.parse(asset.srcMediainfo).video[0].aspectRatio
    : null;
  console.log(aspectRatio);

  return (
    <div
      style={{ borderRadius: isHoverMenuVisible ? 5 : 0 }}
      onMouseOver={() => setIsHoverMenuVisible(true)}
      onMouseLeave={() => setIsHoverMenuVisible(false)}
      className={classnames(
        classes.Asset,
        selectedAssets.includes(asset.assetId) && classes.selected
      )}
      onClick={onClick}
    >
      <div className={classes.preview}>
        <div className={classes.videoLength}>{duration}</div>
        <div className={classes.selectionMark} />
        <video>
          <source src={url} />
        </video>
      </div>
      <div className={classes.footer}>
        <h1>Video Title: {asset.title}</h1>
      </div>
      {isHoverMenuVisible && (
        <>
          {onSelect && (
            <div
              className={classes.emptyCheckbox}
              onClick={(event) => {
                event.stopPropagation();
                onSelect();
              }}
            />
          )}
          <div className={classes.hoverMenu}>
            <div className={classes.hoverButtons}>
              <button
                type="button"
                onClick={(event) => {
                  event.stopPropagation();
                  onDownload(url, asset);
                }}
              >
                Download
              </button>
              <i
                className={classes.deleteIcon}
                role="button"
                tabIndex={0}
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete({ assetId: asset.assetId });
                }}
              >
                Delete
              </i>
            </div>
            <div className={classes.details}>
              <div className={classes.videoDetails}>
                <div className={classes.aspectRatios}>
                  <div
                    className={classnames(
                      classes.nineSixteen,
                      aspectRatio !== 1.778 &&
                        aspectRatio !== 1.77777777778 &&
                        classes.notAvailable
                    )}
                  />
                  <div
                    className={classnames(
                      classes.sixteenNine,
                      aspectRatio !== 0.5625 && classes.notAvailable
                    )}
                  />
                  <div
                    className={classnames(
                      classes.oneOne,
                      aspectRatio !== 1 && classes.notAvailable
                    )}
                  />
                </div>
                <div className={classes.pace}>
                  <div
                    className={classnames(classes.fast, classes.notAvailable)}
                  />
                  <div
                    className={classnames(classes.slow, classes.notAvailable)}
                  />
                </div>
              </div>
              <div className={classes.icon} />
            </div>
          </div>
        </>
      )}
    </div>
  );
}
