import React, { useState } from 'react';

import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Card, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Header from '../../../components/Header';
import FormField from '../../../components/Form/FormField';
import FormButton from '../../../components/Form/StyledButton';
import Alert from '../../../components/Alert';

import classes from './styles.module.scss';

const ValidationSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string()
    .email('Invalid email')
    .required('Please enter your email'),
  organization: Yup.string(),
  username: Yup.string().required('Please enter your last name'),
  password: Yup.string().min(8, 'Too Short!').required('Please enter password'),
});

const RegistrationPage = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  return (
    <>
      <Header />
      <div className={classes.FormContainer}>
        <Card className={classes.Card}>
          <Card.Body className={classes.signupFormBody}>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                organization: '',
                username: '',
                password: '',
              }}
              validationSchema={ValidationSchema}
              onSubmit={(values) => {
                const { email, password, firstName, lastName } = values;
                Auth.signUp({
                  username: email,
                  password,
                  attributes: {
                    given_name: firstName,
                    family_name: lastName,
                  },
                  validationData: [],
                })
                  .then(() => history.push('/verify'))
                  .catch((err) => {
                    setError(err.message);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                // isSubmitting,
              }) => (
                <Form className={classes.form} noValidate>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      type="firstName"
                      placeholder="First Name"
                      onChange={handleChange('firstName')}
                      onBlur={handleBlur('firstName')}
                      value={values.firstName}
                      isInvalid={touched.firstName && errors.firstName}
                    />
                  </div>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      type="lastName"
                      placeholder="Last Name"
                      onChange={handleChange('lastName')}
                      onBlur={handleBlur('lastName')}
                      value={values.lastName}
                      isInvalid={touched.lastName && errors.lastName}
                    />
                  </div>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      type="email"
                      placeholder="Email"
                      onChange={handleChange('email')}
                      onBlur={handleBlur('email')}
                      value={values.email}
                      isInvalid={touched.email && errors.email}
                    />
                  </div>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      type="organization"
                      placeholder="Organization (optional)"
                      onChange={handleChange('organization')}
                      onBlur={handleBlur('organization')}
                      value={values.organization}
                      isInvalid={touched.organization && errors.organization}
                    />
                  </div>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      type="username"
                      placeholder="Username"
                      onChange={handleChange('username')}
                      onBlur={handleBlur('username')}
                      value={values.username}
                      isInvalid={touched.username && errors.username}
                    />
                  </div>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      type="password"
                      placeholder="Password"
                      onChange={handleChange('password')}
                      onBlur={handleBlur('password')}
                      value={values.password}
                      isInvalid={touched.password && errors.password}
                    />
                  </div>
                  <div className={classes.formBtnContainer}>
                    <FormButton
                      label="Create Account"
                      onClick={handleSubmit}
                      isAuth
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </div>
      <Alert message={error} clearMessage={() => setError(null)} />
    </>
  );
};

export default RegistrationPage;
