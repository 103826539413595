import React from 'react';

import Header from '../Header';
import Sidebar from '../Sidebar';

import classes from './styles.module.scss';

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div className={classes.Containter}>
        <Sidebar />
        <div className={classes.mainContainer}>{children}</div>
      </div>
    </>
  );
};

export default Layout;
