/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState, useEffect } from 'react';

import classes from './styles.module.scss';

const Video = ({ index, asset, selectedAssets, setSelectedAssets }) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);

  const videoSrc = asset.mp4Urls ? asset.mp4Urls[0] : null;

  const videoRef = useRef();

  console.log(asset);

  useEffect(() => {
    const hideOverlay = () => {
      setIsOverlayVisible(false);
    };
    const showOverlay = () => {
      if (videoRef?.current?.seeking) {
        return;
      }
      setIsOverlayVisible(true);
    };

    if (videoRef.current) {
      videoRef.current.addEventListener('playing', hideOverlay);
      videoRef.current.addEventListener('pause', showOverlay);
    }
    return () => {
      if (videoRef.current) {
        videoRef.current.removeEventListener('playing', hideOverlay);
        videoRef.current.removeEventListener('pause', showOverlay);
      }
    };
  }, [videoRef]);

  const toggleAssetSelection = () => {
    setSelectedAssets((prevAssets) => {
      if (!selectedAssets.includes(asset.assetId)) {
        const newAssets = [...prevAssets, asset.assetId];
        return newAssets;
      }
      const newAssets = prevAssets.filter(
        (assetId) => assetId !== asset.assetId
      );
      return newAssets;
    });
  };

  return (
    <div className={classes.Video}>
      <video src={videoSrc} ref={videoRef} />
      <div
        className={classes.overlay}
        style={{
          backgroundImage: isOverlayVisible ? '' : 'none',
          backgroundColor: isOverlayVisible ? '' : 'transparent',
        }}
        onClick={(event) => {
          if (event.target.tagName === 'SPAN') {
            return;
          }

          if (videoRef.current.paused) {
            videoRef.current.play();
          } else {
            videoRef.current.pause();
          }
        }}
      >
        <div className={classes.containerCheckBox}>
          <label htmlFor={`checkbox4${index}`}>
            <input
              type="checkbox"
              className={classes.checkbox}
              checked={selectedAssets.includes(asset.assetId)}
              id={`checkbox4${index}`}
              onChange={toggleAssetSelection}
              onClick={(event) => event.stopPropagation()}
            />
            <span className={classes.checkmark} />
          </label>
        </div>
      </div>
    </div>
  );
};

export default Video;
