/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { ProgressBar } from 'react-bootstrap';
import { graphql, compose } from 'react-apollo';
import { graphqlMutation } from 'aws-appsync-react';
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import RadioButton from '../../Form/RadioButton';
import DragAndDropUploader from '../../Content/DragAndDropUploader';
import {
  CreateAsset,
  CrateAutoCropTask,
  CreateProject,
} from '../../../graphQL/mutations';
import {
  listAllAssets,
  getTasksInProgress,
  listUsersProjects,
} from '../../../graphQL/queries';

import StyledButton from '../../Form/StyledButton';

import classes from './styles.module.scss';

const AutoCropForm = ({ invokeAPUpload, createProject, invokeTaskHandler }) => {
  const [aspectRatio, setAspectRatio] = useState('');
  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [projectId, setProjectId] = useState(null);
  const [areCropSettingsApplied, setAreCropSettingsApplied] = useState(false);
  const [isTaskCreated, setIsTaskCreated] = useState(false);

  const history = useHistory();

  const radioChangeHandler = (e) => {
    setAspectRatio(e.target.value);
  };

  const createCropTask = () => {
    if (!areCropSettingsApplied || !aspectRatio) {
      return;
    }

    const aspectRatioParts = aspectRatio.split(':');
    const aspectRatioFloat = +aspectRatioParts[0] / +aspectRatioParts[1];
    setIsTaskCreated(true);
    invokeTaskHandler({
      projectId,
      callId: uuidv4(),
      assets: files.map((file) => file.uuid),
      aspectRatio: aspectRatioFloat,
    }).then(() => history.push(`/projects/${projectId}`));
  };

  const createProjectFromUploadedAssets = () => {
    const id = uuidv4();
    createProject({
      projectId: id,
      title: `My Crop Project ${moment().format('h:mm:ss a')}`,
      description: `My crop project created at ${moment().format(
        'MMMM Do YYYY, h:mm:ss a'
      )}`,
      assets: files.map((file) => file.uuid),
      createdAt: new Date().toISOString(),
    }).then(() => {
      setProjectId(id);
      createCropTask();
    });
  };

  useEffect(() => {
    if (files.length) {
      let progress = 0;
      files.forEach((file) => {
        progress += file.uploadProgress;
      });
      setUploadProgress(progress / files.length);
    }
  }, [files]);

  useEffect(() => {
    if (projectId && areCropSettingsApplied && !isTaskCreated) {
      createCropTask();
    }
  }, [projectId, areCropSettingsApplied, isTaskCreated]);

  return (
    <>
      <div className={classes.CreateProject}>
        {!isUploading ? (
          <>
            <DragAndDropUploader
              files={files}
              setFiles={setFiles}
              setIsUploading={setIsUploading}
              invokeAPUpload={invokeAPUpload}
              multiple={false}
              onAdd={createProjectFromUploadedAssets}
              onClose={() => {}}
            />
          </>
        ) : (
          <div className={classes.autoCropContainer}>
            <ProgressBar now={uploadProgress} bsPrefix={classes.progressBar} />
            <div className={classes.uploading}>
              {uploadProgress === 100 ? 'Uploaded' : 'Uploading...'}
            </div>
            <div className={classes.settingsContainer}>
              <div className={classes.item}>
                <div className={classes.aspectRatio1} />
                <div className={classes.boxInput}>
                  <div className={classes.text}>16:9</div>
                  <RadioButton
                    changed={radioChangeHandler}
                    id="aspectRatio1"
                    isSelected={aspectRatio === '16:9'}
                    value="16:9"
                    name="aspectRatio"
                    isCrop
                  />
                </div>
              </div>
              <div className={classes.item}>
                <div className={classes.aspectRatio2} />
                <div className={classes.boxInput}>
                  <div className={classes.text}>9:16</div>
                  <RadioButton
                    changed={radioChangeHandler}
                    id="aspectRatio2"
                    isSelected={aspectRatio === '9:16'}
                    value="9:16"
                    name="aspectRatio"
                    isCrop
                  />
                </div>
              </div>
              <div className={classes.item}>
                <div className={classes.aspectRatio3} />
                <div className={classes.boxInput}>
                  <div className={classes.text}>1:1</div>
                  <RadioButton
                    changed={radioChangeHandler}
                    id="aspectRatio3"
                    isSelected={aspectRatio === '1:1'}
                    value="1:1"
                    name="aspectRatio"
                    isCrop
                  />
                </div>
              </div>
            </div>
            <div className={classes.btnContainer}>
              <StyledButton
                label="Apply"
                isForm
                disabled={!aspectRatio}
                onClick={() => setAreCropSettingsApplied(true)}
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default compose(
  graphql(listAllAssets, {
    options: {
      fetchPolicy: 'network-only',
    },
    props: (props) => ({
      assets: props.data.listAssets ? props.data.listAssets.items : [],
    }),
  }),

  graphqlMutation(CreateAsset, listAllAssets, 'Asset'),
  graphqlMutation(CreateProject, listUsersProjects, 'Project'),
  graphqlMutation(CrateAutoCropTask, getTasksInProgress, 'Task')
)(AutoCropForm);
