/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';

import { useParams, useHistory } from 'react-router-dom';
import { compose, graphql } from 'react-apollo';
import { graphqlMutation } from 'aws-appsync-react';
import { ProgressBar } from 'react-bootstrap';
import { buildSubscription } from 'aws-appsync';
import gql from 'graphql-tag';

import Button from '../../components/Form/StyledButton';
import Filter from '../../components/Filter';
import Modal from '../../modals/ModalWithCloseButton';
import AutoCropForm from '../../components/Project/AutoCropForm';
import AutoEditForm from '../../components/Project/AutoEditForm';

import Content from '../Content';

import {
  DeleteProject,
  CreateMultipleTasks,
  UpdateTask,
  CreateProject,
  UpdateProject,
} from '../../graphQL/mutations';
import {
  getProject,
  listUsersProjects,
  getTasksInProgress,
  listAllAssets,
} from '../../graphQL/queries';
import { TaskSubscription } from '../../graphQL/subscriptions';

import classes from './styles.module.scss';

const ProjectPage = ({
  client,
  tasks,
  assets,
  invokeTaskHandler,
  data,
  updateTask,
  updateProject,
}) => {
  const [project, setProject] = useState({
    title: '',
    description: '',
    assets: [''],
  });
  const [filteredAssets, setFilteredAssets] = useState([]);
  const [tasksInProgress, setTasksInProgress] = useState([]);
  const [isAutoCropModalVisible, setIsAutoCropModalVisible] = useState(false);
  const [isAutoEditModalVisible, setIsAutoEditModalVisible] = useState(false);
  const [selectedAssets, setSelectedAssets] = useState([]);

  const { id } = useParams();
  const history = useHistory();

  const showAutoCropModal = () => {
    setIsAutoCropModalVisible(true);
  };

  const hideAutoCropModal = () => {
    setIsAutoCropModalVisible(false);
  };

  const showAutoEditModal = () => {
    setIsAutoEditModalVisible(true);
  };

  const hideAutoEditModal = () => {
    setIsAutoEditModalVisible(false);
  };

  const getItem = async () => {
    const result = await client.query({
      query: getProject,
      variables: { projectId: id },
    });

    setProject(result.data.getProject);
  };

  const reFetchRenders = () => {
    client.query({
      query: listAllAssets,
    });
  };

  useEffect(() => {
    getItem();
  }, []);

  useEffect(() => {
    if (tasksInProgress.length) {
      client.subscribe({
        query: TaskSubscription,
        variables: { taskId: tasksInProgress[0].taskId },
      });
    }
  }, [tasksInProgress]);

  useEffect(() => {
    const filteredProjectAssets = assets.filter((asset) =>
      project.assets.includes(asset.assetId)
    );
    setFilteredAssets(filteredProjectAssets);
  }, [assets, project]);

  /* useEffect(() => {
    if (tasks.length) {
      setTasksInProgress((prevTasks) => {
        const projectTasks = tasks.filter((task) => {
          return task.project_id === id;
        });

        if (!tasksInProgress.length) {
          return projectTasks;
        }
        const updatedTasks = prevTasks.map((task) => {
          if (!projectTasks.find) {
            return task;
          }
          const updatedProgress = projectTasks.find(
            (pTask) => pTask.taskId === task.taskId
          ).parent_progress;
          return {
            ...task,
            parent_progress: updatedProgress || task.parent_progress,
          };
        });
        return updatedTasks;
      });
    }
  }, [tasks]); */

  useEffect(() => {
    if (tasks.length) {
      console.log('here', tasks);
      const projectTasks = tasks.filter((task) => {
        return task.project_id === id;
      });
      console.log('here', projectTasks);
      setTasksInProgress(projectTasks);
    }
  }, [tasks]);

  /* useEffect(() => {
    if (tasksInProgress.length) {
      console.log(tasksInProgress[0].taskId);
      const TaskSubscription2 = gql`
        subscription MySubscription {
          onUpdateTask {
            parent_progress
            taskId
          }
        }
      `;
      data.subscribeToMore(
        buildSubscription(TaskSubscription2, getTasksInProgress)
      );
    }
  }, [tasksInProgress]); */

  /* useEffect(() => {
    if (tasksInProgress.length) {
      setTimeout(() => {
        updateTask({
          taskId: tasksInProgress[0].taskId,
          parent_progress: Math.random().toFixed(2),
        });
      }, 5000);
    }
  }, [tasksInProgress]); */

  return (
    <>
      <div className={classes.ProjectPage}>
        <div className={classes.projectSection}>
          <div className={classes.projectInfo}>
            <div className={classes.boxProject}>
              <div className={classes.projectName}>{project.title}</div>
              <div className={classes.projectDescription}>
                {project.description}
              </div>
            </div>
            {tasksInProgress.length ? (
              <div className={classes.boxProjectEdit}>
                {tasksInProgress.map((task, index) => {
                  return (
                    <div className={classes.editOption} key={task.taskId}>
                      <div className={classes.text}>Edit {index + 1}</div>
                      <div className={classes.bar}>
                        <ProgressBar
                          now={task.parent_progress * 100}
                          bsPrefix={classes.progressBar}
                        />
                      </div>
                      <div className={classes.boxImg}>
                        <svg
                          width="19"
                          height="19"
                          viewBox="0 0 19 19"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.0435 1L3.21741 12.8261L6.17393 15.7826L18 3.95652L15.0435 1Z"
                            stroke="white"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M4.69565 17.2608L1.73913 14.3043L1 18L4.69565 17.2608Z"
                            stroke="white"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M5.43481 10.6086L8.39134 13.5652"
                            stroke="white"
                          />
                        </svg>
                      </div>
                      <div className={classes.btnContainer}>
                        <Button label="Download" isEdit />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
          </div>
          <div className={classes.btnContainerAction}>
            <div className={classes.btnContainer}>
              <Button
                label="Search"
                onClick={() => history.push(`/search/${id}`)}
              />
            </div>
            <div className={classes.btnContainer}>
              <Button label="Auto Edit" onClick={showAutoEditModal} />
            </div>
            <div className={classes.btnContainer}>
              <Button label="Auto Graphics" />
            </div>
            <div className={classes.btnContainer}>
              <Button label="Auto Crop" onClick={showAutoCropModal} />
            </div>
          </div>
        </div>
        <Filter label="Renders" isRenders projectId={id} />
        <Content
          projectId={id}
          projectAssets={filteredAssets}
          reFetchProject={getItem}
          selectedProjectAssets={selectedAssets}
          setSelectedProjectAssets={setSelectedAssets}
        />
      </div>
      <Modal open={isAutoCropModalVisible} onClose={hideAutoCropModal}>
        <AutoCropForm
          updateProject={updateProject}
          projectId={id}
          invokeTaskHandler={invokeTaskHandler}
          reFetchRenders={reFetchRenders}
          onClose={hideAutoCropModal}
          assets={
            selectedAssets.length
              ? filteredAssets.filter((asset) =>
                  selectedAssets.includes(asset.assetId)
                )
              : filteredAssets
          }
        />
      </Modal>
      <Modal open={isAutoEditModalVisible} onClose={hideAutoEditModal}>
        <AutoEditForm
          history={history}
          assets={
            selectedAssets.length
              ? filteredAssets.filter((asset) =>
                  selectedAssets.includes(asset.assetId)
                )
              : filteredAssets
          }
        />
      </Modal>
    </>
  );
};

export default compose(
  graphql(getTasksInProgress, {
    options: {
      fetchPolicy: 'network-only',
    },
    props: (props) => ({
      tasks: props.data.listTasks ? props.data.listTasks.items : [],
      data: props.data,
    }),
  }),
  graphql(listAllAssets, {
    options: {
      fetchPolicy: 'network-only',
    },
    props: (props) => ({
      assets: props.data.listAssets ? props.data.listAssets.items : [],
    }),
  }),
  graphqlMutation(DeleteProject, listUsersProjects, 'Project'),
  graphqlMutation(CreateMultipleTasks, getTasksInProgress, 'Task'),
  graphqlMutation(UpdateTask, null, 'Task'),
  graphqlMutation(CreateProject, listUsersProjects, 'Project'),
  graphqlMutation(UpdateProject, listUsersProjects, 'Project')
)(ProjectPage);
