import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { Card, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as Yup from 'yup';

import Header from '../../../components/Header';
import FormField from '../../../components/Form/FormField';
import FormButton from '../../../components/Form/StyledButton';
import Alert from '../../../components/Alert';

import classes from './styles.module.scss';

const ValidationSchema = Yup.object().shape({
  username: Yup.string().required('Please enter your username'),
  code: Yup.string().required('Please enter verify code'),
});

const VerifyPage = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  return (
    <>
      <Header />
      <div className={classes.FormContainer}>
        <Card className={classes.Card}>
          <Card.Body className={classes.signupFormBody}>
            <Formik
              initialValues={{
                username: '',
                code: '',
              }}
              validationSchema={ValidationSchema}
              onSubmit={(values) => {
                const { username, code } = values;
                Auth.confirmSignUp(username, code, {
                  forceAliasCreation: true,
                })
                  .then(() => history.push('/login'))
                  .catch((err) => {
                    setError(err.message);
                  });
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                // isSubmitting,
              }) => (
                <Form className={classes.form} noValidate>
                  <div className={classes.formFieldContainer}>
                    <FormField
                      type="text"
                      placeholder="Username"
                      onChange={handleChange('username')}
                      onBlur={handleBlur('username')}
                      value={values.username}
                      isInvalid={touched.username && errors.username}
                    />
                  </div>
                  <div className={classes.FieldContainer}>
                    <FormField
                      type="text"
                      placeholder="Verify code"
                      onChange={handleChange('code')}
                      onBlur={handleBlur('code')}
                      value={values.code}
                      isInvalid={touched.code && errors.code}
                    />
                  </div>
                  {/* {error && <FormErrorMessage errors={error} />} */}
                  <div className={classes.formBtnContainer}>
                    <FormButton label="Verify" onClick={handleSubmit} isAuth />
                  </div>
                </Form>
              )}
            </Formik>
          </Card.Body>
        </Card>
      </div>
      <Alert message={error} clearMessage={() => setError(null)} />
    </>
  );
};

export default VerifyPage;
