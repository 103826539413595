import React from 'react';

import ProjectItem from '../ProjectItem';

import classes from './styles.module.scss';

const ProjectsList = ({ projects }) => {
  return (
    <div className={classes.PlacesList}>
      <table className={classes.table}>
        <thead className={classes.tableHeadContainer}>
          <tr className={classes.tableHeader}>
            <th className={classes.th}>Project Name</th>
            <th className={classes.th}>Project Description</th>
            <th className={classes.th}>Date Created</th>
          </tr>
        </thead>
        <tbody>
          {projects && projects.length ? (
            projects.map((item) => (
              <ProjectItem key={item.projectId} project={item} />
            ))
          ) : (
            <tr>
              <td />
              <td />
              <td />
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ProjectsList;
