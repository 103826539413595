/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useRef, useState } from 'react';

import Checkbox from '../../../Form/Checkbox';

import classes from './styles.module.scss';

export default function Video({ video, onOptionsChange }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(true);
  const videoRef = useRef();
  const source = video.asset.mp4Urls ? video.asset.mp4Urls[0] : null;

  return (
    <div className={classes.Video}>
      <div className={classes.preview}>
        <div
          className={classes.overlay}
          style={{ opacity: isOverlayVisible ? 1 : 0 }}
          onClick={() => {
            if (videoRef.current.paused) {
              videoRef.current.play();
            } else {
              videoRef.current.pause();
            }
            setIsOverlayVisible((prevState) => !prevState);
          }}
        >
          <button type="button">Play</button>
        </div>
        <video ref={videoRef}>
          <source src={source} />
        </video>
      </div>
      <div className={classes.inputs}>
        <div className={classes.checkboxContainer}>
          <Checkbox
            dataId={video.asset.assetId}
            id={video.asset.assetId + 1}
            isChecked={video.options.includes('169')}
            name="169"
            label="16:9"
            onChange={onOptionsChange}
          />
        </div>
        <div className={classes.checkboxContainer}>
          <Checkbox
            dataId={video.asset.assetId}
            id={video.asset.assetId + 2}
            isChecked={video.options.includes('916')}
            name="916"
            label="9:16"
            onChange={onOptionsChange}
          />
        </div>
        <div className={classes.checkboxContainer}>
          <Checkbox
            dataId={video.asset.assetId}
            id={video.asset.assetId + 3}
            isChecked={video.options.includes('11')}
            name="11"
            label="1:1"
            onChange={onOptionsChange}
          />
        </div>
      </div>
    </div>
  );
}
