import React, { useState } from 'react';

import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { compose } from 'react-apollo';
import { graphqlMutation } from 'aws-appsync-react';
import { useHistory } from 'react-router-dom';

import DragAndDropUploader from '../../Content/DragAndDropUploader';
import classes from './styles.module.scss';
import { CreateAsset, CreateProject } from '../../../graphQL/mutations';
import { listUsersProjects, listAllAssets } from '../../../graphQL/queries';

function AutoSearchForm({ invokeAPUpload, createProject, onClose }) {
  const [files, setFiles] = useState([]);

  const history = useHistory();

  const createProjectFromUploadedAssets = () => {
    const id = uuidv4();
    createProject({
      projectId: id,
      title: `My Search Project ${moment().format('h:mm:ss a')}`,
      description: `My search project created at ${moment().format(
        'MMMM Do YYYY, h:mm:ss a'
      )}`,
      assets: files.map((file) => file.uuid),
      createdAt: new Date().toISOString(),
    }).then(() => {
      onClose();
      history.push(`/search/${id}`);
    });
  };

  return (
    <>
      <div className={classes.AutoSearchForm}>
        <>
          <DragAndDropUploader
            files={files}
            setFiles={setFiles}
            setIsUploading={() => {}}
            invokeAPUpload={invokeAPUpload}
            multiple
            onAdd={createProjectFromUploadedAssets}
            onClose={() => {}}
          />
        </>
      </div>
    </>
  );
}

export default compose(
  graphqlMutation(CreateAsset, listAllAssets, 'Asset'),
  graphqlMutation(CreateProject, listUsersProjects, 'Project')
)(AutoSearchForm);
