import gql from 'graphql-tag';

export const TaskSubscription = gql`
  subscription MySubscription($taskId: String = "") {
    onUpdateTask(taskId: $taskId) {
      parent_progress
      taskId
    }
  }
`;
