import React, { useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import classnames from 'classnames';

import { clearAuthData } from '../../helpers/authStorage';
import AutoSearchForm from '../Dashboard/AutoSearchForm';
import Modal from '../../modals/ModalWithCloseButton';

import classes from './styles.module.scss';

const Sidebar = () => {
  const [isAutoSearchModalVisible, setIsAutoSearchModalVisible] =
    useState(false);

  const history = useHistory();

  const logout = async () => {
    await Auth.signOut();
    clearAuthData();
    history.push('/login');
  };

  const showAutoSearchModal = () => {
    setIsAutoSearchModalVisible(true);
  };

  const hideAutoSearchModal = () => {
    setIsAutoSearchModalVisible(false);
  };

  return (
    <div className={classes.Sidebar}>
      <div className={classes.SidebarInner}>
        <div className={classes.SidebarBox}>
          <div className={classes.profile}>
            <div className={classes.profileImageBox}>
              <svg
                width="37"
                height="44"
                viewBox="0 0 37 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <ellipse cx="18.5" cy="11" rx="11.5" ry="11" fill="white" />
                <path
                  d="M37 44C37 40.8483 36.5215 37.7274 35.5918 34.8156C34.6621 31.9038 33.2994 29.258 31.5815 27.0294C29.8636 24.8008 27.8242 23.033 25.5796 21.8269C23.3351 20.6208 20.9295 20 18.5 20C16.0705 20 13.6649 20.6208 11.4204 21.8269C9.17583 23.033 7.13641 24.8008 5.41852 27.0294C3.70064 29.258 2.33794 31.9038 1.40823 34.8156C0.478516 37.7274 -2.1239e-07 40.8483 0 44L18.5 44H37Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className={classes.profileInfo}>
              <div className={classes.userName}>User Name</div>
              <div className={classes.accountType}>Acount type</div>
            </div>
          </div>
          <div className={classes.navigation}>
            <nav>
              <ul className={classes.listContainer}>
                <li className={classes.liItem}>
                  <span className={classes.rect} />
                  <Link to="/dashboard" className={classes.navItem}>
                    Dashboard
                  </Link>
                </li>
                <li className={classes.liItem}>
                  <span className={classes.rect} />
                  <Link to="/content" className={classes.navItem}>
                    Content
                  </Link>
                </li>
                <li className={classes.liItem}>
                  <span className={classes.rect} />
                  <Link to="/projects" className={classes.navItem}>
                    Projects
                  </Link>
                </li>
                <li className={classes.liItem}>
                  <span className={classes.rect} />
                  <span
                    className={classes.navItem}
                    onClick={showAutoSearchModal}
                    role="button"
                    tabIndex={0}
                  >
                    Search
                  </span>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div className={classes.SidebarBox}>
          <div className={classes.navigation}>
            <nav>
              <ul className={classes.listContainer}>
                <li className={classes.liItem}>
                  <span className={classes.rect} />
                  <Link to="/settings" className={classes.navItem}>
                    Settings
                  </Link>
                </li>
                <li
                  className={classnames(classes.liItem, classes.btnContainer)}
                >
                  <div className={classes.LogOut}>
                    <button
                      type="button"
                      onClick={logout}
                      className={classes.LogOutBtn}
                    >
                      Log Out
                    </button>
                  </div>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <Modal open={isAutoSearchModalVisible} onClose={hideAutoSearchModal}>
        <AutoSearchForm onClose={hideAutoSearchModal} />
      </Modal>
    </div>
  );
};

export default Sidebar;
