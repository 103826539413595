import React from 'react';
import Amplify from 'aws-amplify';

/* import Authentication from './components/Authentication'; */
import Routes from './routes';
import './index.scss';

import {
  REACT_APP_REGION,
  REACT_APP_CLIENT_ID,
  REACT_APP_USER_POOL,
  REACT_APP_S3_VIDEO_BUCKET,
  REACT_APP_USER_POOL_ID,
} from './constants/main';

const amplifyConfig = {
  Auth: {
    region: REACT_APP_REGION,
    userPoolId: REACT_APP_USER_POOL,
    userPoolWebClientId: REACT_APP_CLIENT_ID,
    identityPoolId: REACT_APP_USER_POOL_ID,
  },
  Storage: {
    AWSS3: {
      bucket: REACT_APP_S3_VIDEO_BUCKET,
      region: REACT_APP_REGION,
    },
  },
};

Amplify.configure(amplifyConfig);

function App() {
  return (
    <div className="App">
      {/* <Authentication /> */}
      <Routes />
    </div>
  );
}

export default App;
