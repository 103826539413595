import gql from 'graphql-tag';

export const CreateAsset = gql`
  mutation createAsset(
    $assetId: String = ""
    $dynamoTable: String = "main-AP-RCM2KMXBY2X3-assets"
    $fileType: String = "video"
    $rekCeleb: Boolean = false
    $rekFace: Boolean = false
    $rekLabel: Boolean = false
    $rekSegment: Boolean = false
    $rekText: Boolean = false
    $storyline: String = "This is a new video"
    $textEmbed: Boolean = false
    $title: String = "New Video"
    $transcript: Boolean = false
    $transcriptBucket: String = "transcripts205928-dev"
    $foveaType: String = "raw_video"
  ) {
    invokeAPUpload(
      input: {
        assetId: $assetId
        title: $title
        storyline: $storyline
        textEmbed: $textEmbed
        fileType: $fileType
        transcript: $transcript
        rekLabel: $rekLabel
        rekFace: $rekFace
        rekText: $rekText
        rekSegment: $rekSegment
        rekCeleb: $rekCeleb
        dynamoTable: $dynamoTable
        transcriptBucket: $transcriptBucket
        foveaType: $foveaType
      }
    ) {
      result
    }
  }
`;

export const CrateAutoCropTask = gql`
  mutation MyMutation(
    $callId: String = ""
    $assets: [String] = [""]
    $taskOrder: [String] = ["autoCrop"]
    $aspectRatio: Float = 1.0
    $projectId: String = ""
  ) {
    invokeTaskHandler(
      input: {
        callId: $callId
        pipelines: [
          {
            assets: $assets
            taskOrder: $taskOrder
            autoCrop: { aspectRatio: $aspectRatio }
          }
        ]
        projectId: $projectId
      }
    ) {
      callId
      parentTaskIds
    }
  }
`;

export const CrateAutoEditTask = gql`
  mutation MyMutation(
    $callId: String = ""
    $assets: [String] = [""]
    $taskOrder: [String] = ["autoEdit", "autoCrop"]
    $targetDuration: Float = 30
    $pace: String = "fast"
    $aspectRatio: Float = 1.5
    $projectId: String = ""
  ) {
    invokeTaskHandler(
      input: {
        callId: $callId
        pipelines: [
          {
            assets: $assets
            taskOrder: $taskOrder
            autoEdit: { targetDuration: $targetDuration, pace: $pace }
            autoCrop: { aspectRatio: $aspectRatio }
          }
        ]
        projectId: $projectId
      }
    ) {
      callId
      parentTaskIds
    }
  }
`;

export const CreateMultipleTasks = gql`
  mutation MyMutation(
    $callId: String = ""
    $projectId: String = ""
    $pipelines: [TaskPipeline]
  ) {
    invokeTaskHandler(
      input: { callId: $callId, pipelines: $pipelines, projectId: $projectId }
    ) {
      callId
      parentTaskIds
    }
  }
`;

/* export const CreateMultipleAutoCropTask = gql`
  mutation MyMutation(
    $callId: String = ""
    $pipelines: [TaskPipeline]
    $projectId: String = ""
  ) {
    invokeTaskHandler(
      input: { callId: $callId, pipelines: $pipelines, projectId: $projectId }
    ) {
      callId
      parentTaskIds
    }
  }
`; */

export const CreateProject = gql`
  mutation CreateNewProject(
    $projectId: String!
    $title: String!
    $description: String!
    $assets: [String!]
    $createdAt: String = ""
  ) {
    createProject(
      input: {
        projectId: $projectId
        description: $description
        title: $title
        assets: $assets
        createdAt: $createdAt
      }
    ) {
      projectId
    }
  }
`;

export const UpdateProject = gql`
  mutation UpdateProject(
    $projectId: String!
    $title: String
    $description: String
    $assets: [String]
    $tasks: [String]
  ) {
    updateProject(
      input: {
        projectId: $projectId
        description: $description
        title: $title
        assets: $assets
        tasks: $tasks
      }
    ) {
      projectId
    }
  }
`;

export const DeleteProject = gql`
  mutation DeleteProject($projectId: String!) {
    deleteProject(input: { projectId: $projectId }) {
      projectId
    }
  }
`;

export const DeleteAsset = gql`
  mutation MyMutation($assetId: String = "") {
    deleteAsset(input: { assetId: $assetId }) {
      assetId
    }
  }
`;

export const UpdateTask = gql`
  mutation MyMutation($taskId: String = "", $parent_progress: String = "") {
    updateTask(input: { taskId: $taskId, parent_progress: $parent_progress }) {
      parent_progress
      taskId
    }
  }
`;
