import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { graphql, compose } from 'react-apollo';
import { graphqlMutation } from 'aws-appsync-react';

import Modal from '../../modals/ModalWithCloseButton';
import Button from '../../components/Form/StyledButton';
import ProjectsList from '../../components/Projects/ProjectsList';
import CreateProjectForm from '../../components/Projects/CreateProjectForm';

import { CreateProject } from '../../graphQL/mutations';
import { listUsersProjects } from '../../graphQL/queries';

import classes from './styles.module.scss';

const ProjectsPage = ({ projects, createProject, client }) => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const reFetch = () => {
    client.query({ query: listUsersProjects });
  };
  const createItem = (state, idsAsset) => {
    createProject({
      projectId: uuidv4(),
      title: state.name,
      description: state.description,
      assets: idsAsset,
      createdAt: new Date().toISOString(),
    }).then(() => {
      reFetch();
    });
  };

  return (
    <>
      <div className={classes.ProjectsPage}>
        <div className={classes.btnContainer}>
          <div className={classes.btn}>
            <Button
              label="Create New Project"
              onClick={handleShow}
              isDashboard
            />
          </div>
        </div>
        <ProjectsList projects={projects} />
      </div>
      <Modal open={show} onClose={handleClose}>
        <CreateProjectForm
          onClose={handleClose}
          createItem={createItem}
          onAdd={reFetch}
        />
      </Modal>
    </>
  );
};

export default compose(
  graphql(listUsersProjects, {
    options: {
      fetchPolicy: 'network-only',
    },
    props: (props) => ({
      projects: props.data.listProjects ? props.data.listProjects.items : [],
    }),
  }),
  graphqlMutation(CreateProject, listUsersProjects, 'Project')
)(ProjectsPage);
